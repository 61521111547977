<template>
      <div div v-if="this.open" class="col-md-6">
        <div class="card">
          <header class="card-header">
            <h4 v-if="!this.isNew" class="card-title mt-2">Editar Categoria</h4>
            <h4 v-else class="card-title mt-2">Agregar Categoria</h4>
          </header>
          <article class="card-body">
            <div>
              <div class="form-row">
                <div class="col form-group">
                  <label>Vincular categoria existente</label>

                  <b-form-select
                    v-model="category"
                    class="mb-3"
                    size="sm"
                    id="category"
                    name="category"
                    aria-describedby="categoryFeedback"
                    >
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Escoger una existente --</b-form-select-option>
                    </template>
                    <option :value="categoryItem" v-for="(categoryItem,bind) in autocompleteCategorys" v-bind:key="bind">
                      {{categoryItem.name.es || categoryItem.name.en || categoryItem.category}}
                      </option>
                  </b-form-select>
                </div>
                <div class="col form-group">
                  <label>O crear una nueva categoria</label>
                  <b-form-input
                    id="categoryTypeEsModel"
                    type="text"
                    name="categoryTypeEsModel"
                    v-model="category.name.es"
                    placeholder="Categoria en español"
                    size="sm"
                    :required="true"
                    trim
                  ></b-form-input>
                  <label>Traduccion ingles</label>
                  <b-form-input
                    id="categoryTypeEnModel"
                    type="text"
                    name="categoryTypeEnModel"
                    v-model="category.name.en"
                    placeholder="Categoria en ingles"
                    size="sm"
                    :required="true"
                    trim
                  ></b-form-input>
                </div>
              </div>
              <div class="form-row">
                <div class="col form-group">
                  <label>Subcategoria</label>
                  <input
                    class="form-control"
                    v-model="category.nameSubcategory.es"
                    required
                    trim
                  >

                  <label>traduccion ingles</label>
                  <input
                    class="form-control"
                    v-model="category.nameSubcategory.en"
                    required
                    trim
                  >
                </div>
              </div>
              <div class="form-row">
                <div class="col form-group">
                  <label>Imagen</label>
                  <b-form-file 
                    :multiple="false" 
                    v-model="imageObj" 
                    class="mt-3"
                    @input="uploadImages"
                    @change="uploadImages"
                    accept="image/png, image/jpeg"
                   ></b-form-file>

                  <img
                    :src="this.category.imageUrl" 
                    alt="image for category" 
                    width="50%" height="auto" />
                </div>
              </div>

              <!-- form-group end.// -->
              <div class="form-group">
                <button
                  v-if="!this.isNew"
                  type="button"
                  @click="handleEditCategory()"
                  class="au-btn au-btn-icon au-btn--green"
                >Editar Categoria</button>
                <button
                  v-else
                  type="button"
                  @click="handleAddCategory()"
                  class="au-btn au-btn-icon au-btn--green"
                >Agregar Categoria</button>
                &nbsp;
                <button
                  type="button"
                  @click="handleCancel()"
                  class="au-btn au-btn-icon au-btn--cancel"
                >Cancelar</button>
              </div>
            </div>
          </article>
          <!-- card-body end .// -->
        </div>
        <!-- card.// -->
      </div>
</template>

<script>
import { convertToSlug } from '../../utils/dataParser';
import ProxyUrls from '@/constants/ProxyUrls';

export default {
  name: 'CategoryForm',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: true,
    },
    categoryModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    autocompleteCategorys() {
      const yourarray = this.$store.getters['categoryStore/getcategorys'];

      const uniqCategoriesObj = _.uniqBy(yourarray, 'category').map(item => ({
        category: item.category,
        name: {
          es: item.name?.es || '',
          en: item.name?.en || '',
        },
        subcategory: '',
        nameSubcategory: { es: '', en: ''},
        imageUrl: item.imageUrl,
      })).sort((a, b) => a.category.localeCompare(b.category));
      // const uniqSubcategoriesObj = _.uniqBy(yourarray, 'subcategory').map(item => ({
      //   category: item.subcategory,
      //   name: {
      //     es: item.nameSubcategory?.es || '',
      //     en: item.nameSubcategory?.en || '',
      //   },
      //   subcategory: '',
      //   nameSubcategory: { es: '', en: ''},
      // }));
      
      return uniqCategoriesObj;
    },
  },
  data() {
    return {
      category: {
        category: '',
        subcategory: '',
        name: { es: '', en: ''},
        nameSubcategory: { es: '', en: ''},
        imageUrl: '',
      },
      imageObj: null,
    };
  },
  methods: {
    handleEditCategory() {
      this.category.category = convertToSlug(this.category.name.es);
      this.category.subcategory = convertToSlug(this.category.nameSubcategory.es);
      this.$emit('edit', this.category);
    },
    handleAddCategory() {
      this.category.category = convertToSlug(this.category.name.es);
      this.category.subcategory = convertToSlug(this.category.nameSubcategory.es);
      this.$emit('add', this.category);
    },
    handleCancel() {
      this.$emit('cancel');
    },
    async uploadImages() {
      if (!this.imageObj) {
        return;
      }
      try {
        const res = await this.$axios({
          method: 'get',
          url: ProxyUrls.predefinedUrls,
          params: {
            numberOfFeaturedImages: 1,
          },
        });

        const data = res.data.responseData;
        if (data.featuredImageUrls.length == 1) {
          const imageUpload = data.featuredImageUrls[0];
          const imageUploadResult = await this.$axios({
            headers: {
              'Content-Type': 'image/png',
            },
            method: 'put',
            url: imageUpload.uploadUrl,
            data: this.imageObj,
            withCredentials: false,
          });

          if (imageUploadResult.status == 200 ) {
            this.category = { ...this.category, imageUrl: imageUpload.liveUrl};
          }
        }
      } catch (error) {
        console.error('error in uploadImages', error);
      }
    },
  },
  created() {
    if (this.categoryModel && !this.isNew) {
      this.category = this.categoryModel;
    }
  },
};
</script>