<template>
  <div id="brand-form">
    <div class="attrib-space"></div>
    <div>
        <b-form-input
          id="name"
          type="text"
          name="brand"
          :state="brandState"
          v-model="brand.name"
          placeholder="Nombre de la marca"
          aria-describedby="brandFeedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="brandFeedback">
          <!-- This will only be shown if the preceeding input has an invalid state -->
          Este campo tiene que ser llenado
        </b-form-invalid-feedback>
    </div>
    <div class="buttons align-right">
      <b-btn variant="primary" size="sm" @click="cancel()">Cancelar</b-btn>
      <b-btn variant="success" size="sm" @click="save()">Guardar</b-btn>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'BrandForm',
  props: {
    propValue: {
      required: false,
      // type: Object
    },
  },
  data() {
    return {
      brand: {
        name: null
      },
    };
  },

  computed: {
    brandState() {
      if (this.brand.name == null) return null;
      return this.brand.name.length > 0;
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },

    async save() {
      if (this.validateForms()) {
        await this.$store.dispatch('brandStore/addBrand', this.brand);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Exitoso',
        });
        this.$emit('save');
      } else {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Los campos tienen que ser llenador.',
        });
      }
    },
    validateForms() {
      return this.brand && this.brand.name && this.brand.name.length>0;
    }
  },
};
</script>