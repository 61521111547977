<template>
  <div class="col-md-12">
    <div class="row justify-content-left">
      <div v-if="!this.viewOperation" class="col-md-12">
        <div class="card">
          <header class="card-header">
            <div class="row">
              <div class="col-3">
                <h4 class="card-title mt-2">Proveedores</h4>
              </div>

              <div class="col-9 align-right">
                <button
                  v-if="managePermissionGranted"
                  type="button"
                  class="au-btn au-btn-icon au-btn--green"
                  @click="handleAddProviderButtonClick()"
                >
                  + Nuevo proveedor
                </button>
              </div>
            </div>
          </header>
          <article class="card-body">
            <input
              class="form-control"
              v-model="query"
              type="text"
              placeholder="Buscar Proveedores por nombre o contacto"
            />
            <table class="table table-striped" id="content_loop">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Contacto</th>
                  <th>Telefono</th>
                  <th>Direccion</th>
                  <th>Ciudad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(provider, index) in providers"
                  v-if="
                    providers.length > 0 &&
                      (provider.name.toUpperCase().includes(query.toUpperCase()) ||
                        provider.contactName.toUpperCase().includes(query.toUpperCase()))
                  "
                  v-bind:key="index"
                >
                  <td>{{ provider.name }}</td>
                  <td>{{ provider.contactName }}</td>
                  <td>{{ provider.contactPhone }}</td>
                  <td>{{ provider.address }}</td>
                  <td>{{ provider.city }}, {{ provider.country }}</td>
                  <td v-if="managePermissionGranted">
                    &nbsp;
                    <a @click="editProvider(provider)">
                      <i class="fa fa-edit" style="color:green"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <!-- card-body end .// -->
        </div>
        <!-- card.// -->
      </div>

      <div div v-if="this.viewOperation" class="col-md-10">
        <div class="card">
          <header class="card-header">
            <h4 v-if="!this.isAddView" class="card-title mt-2">Editar Proveedor</h4>
            <h4 v-else class="card-title mt-2">Agregar Proveedor</h4>
          </header>
          <article class="card-body">
            <div>
              <div>
                <b-form-group horizontal :label-cols="6" label="Nombre de la empresa:" label-for="productName">
                  <b-form-input
                    id="providerName"
                    type="text"
                    name="providerName"
                    v-model="provider.name"
                    placeholder="Nombre del proveedor"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group horizontal :label-cols="6" label="Persona de Contactp:" label-for="providerContactName">
                  <b-form-input
                    id="providerContactName"
                    type="text"
                    name="providerContactName"
                    v-model="provider.contactName"
                    placeholder="Nombre de contacto"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group horizontal :label-cols="6" label="Telefono:" label-for="providerContactPhone">
                  <b-form-input
                    id="providerContactPhone"
                    type="text"
                    name="providerContactPhone"
                    v-model="provider.contactPhone"
                    placeholder="Telefono del Proveedor"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group horizontal :label-cols="6" label="Direccion:" label-for="providerAddress">
                  <b-form-input
                    id="providerAddress"
                    type="text"
                    name="providerAddress"
                    v-model="provider.address"
                    placeholder="Direccion calle"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group horizontal :label-cols="6" label="Ciudad:" label-for="providerCity">
                  <b-form-input
                    id="providerCity"
                    type="text"
                    name="providerCity"
                    v-model="provider.city"
                    placeholder="Ciudad"
                    size="sm"
                  ></b-form-input>
                </b-form-group>

                <b-form-group horizontal :label-cols="6" label="Pais:" label-for="providerCountry">
                  <b-form-input
                    id="providerCountry"
                    type="text"
                    name="providerCountry"
                    v-model="provider.country"
                    placeholder="Pais"
                    size="sm"
                  ></b-form-input>
                </b-form-group>
              </div>

              <!-- form-group end.// -->
              <div class="form-group">
                <button
                  v-if="!this.isAddView"
                  type="button"
                  @click="handleEditProvider()"
                  class="au-btn au-btn-icon au-btn--green"
                >
                  Edita Proveedor
                </button>
                <button v-else type="button" @click="handleAddProvider()" class="au-btn au-btn-icon au-btn--green">
                  Crear Proveedor
                </button>
                &nbsp;
                <button type="button" @click="handleCancel()" class="au-btn au-btn-icon au-btn--cancel">
                  Cancelar
                </button>
              </div>
            </div>
          </article>
          <!-- card-body end .// -->
        </div>
        <!-- card.// -->
      </div>
    </div>
  </div>
</template>

<script>
import Permission from '@/constants/permissions';
import { mapGetters } from 'vuex';

export default {
  components: {},
  name: 'ProviderPage',
  props: {
    addProviderRequest: {
      required: false,
      type: Boolean
    },
    editProviderRequestObject: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      provider: {
        name: '',
        contactName: '',
        contactPhone: '',
        address: '',
        city: '',
        country: 'El Salvador'
      },
      isAddView: true,
      viewOperation: false,
      query: ''
    };
  },
  async created() {
    await this.$store.dispatch('providerStore/getProviders');
  },
  computed: {
    providers() {
      return this.$store.getters['providerStore/getProviders'];
    },
    managePermissionGranted() {
      if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
      return this.permissions && this.permissions.indexOf(Permission.INVENTORY_MANAGE) >= 0;
    },
    ...mapGetters({
      permissions: 'authStore/permissions'
    })
  },
  mounted() {
    if (this.addProviderRequest) {
      this.viewOperation = true;
      this.isAddView = true;
    }
    if (this.editProviderRequestObject) {
      this.viewOperation = true;
      this.isAddView = false;
      this.provider = this.editProviderRequestObject;
    }
  },
  methods: {
    editProvider(providerData) {
      this.viewOperation = true;
      this.provider = _.cloneDeep(providerData);
      this.isAddView = false;
    },
    handleAddProviderButtonClick() {
      this.viewOperation = true;
    },
    handleCancel() {
      this.isAddView = true;
      this.viewOperation = false;
      this.resetProvider();
    },
    async handleAddProvider() {
      try {
        await this.$store.dispatch('providerStore/addProvider', this.provider);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Exitoso'
        });
        this.isAddView = true;
        this.viewOperation = false;
        this.resetProvider();
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo adding/editing Provider'
        });
      }
    },
    async handleEditProvider() {
      try {
        await this.$store.dispatch('providerStore/editProvider', this.provider);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Edicion con exito'
        });
        this.isAddView = true;
        this.viewOperation = false;
        this.resetProvider();
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Edicion ha fallado'
        });
      }
    },
    resetProvider() {
      this.provider = {
        name: '',
        contactName: '',
        contactPhone: '',
        address: '',
        city: '',
        country: 'El Salvador'
      };
    }
  }
};
</script>

<style lang="scss" scoped></style>
