export const DesignTypes = [
  {
    type: 'Stripe',
    productLimit: 4,
  },
  // {
  //   type: 'Canvas Left Cracked',
  //   productLimit: 1,
  // },
  // {
  //   type: 'Canvas Right Cracked',
  //   productLimit: 1,
  // },
  {
    type: 'Banner',
    productLimit: 0,
  }

];

export default DesignTypes;
