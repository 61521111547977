<template>
  <div>
    <div v-if="managePermissionGranted" class="card" id="inventory-form">
      <header class="card-header">
        <h4 class="card-title mt-2">Nuevo movimiento de inventario</h4>
      </header>
      <article class="card-body" v-if="inventory.product && inventory.product != null && inventory.product._id">
        <div>
          <b-form-group horizontal :label-cols="6" label="Nombre del Producto:" label-for="productName">
            <b-form-input
              id="productName"
              type="text"
              name="productName"
              v-model="inventory.product.name"
              placeholder="Producto"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
          <b-form-group horizontal :label-cols="6" label="Codigo Correlativo :" label-for="codeInc">
            <b-form-input
              id="codeInc"
              type="text"
              name="codeInc"
              v-model="inventory.product.code_inc"
              placeholder="Codigo Correlativo"
              size="sm"
              :disabled="true"
            ></b-form-input>
          </b-form-group>

          <b-form-group horizontal :label-cols="6" label="Fecha del documento:" label-for="inventoryDate">
            <b-form-datepicker
              id="inventoryDate"
              v-model="inventory.date"
              :state="dateState"
              aria-describedby="dateFeedback"
              locale="es"
              size="sm"
            >
            </b-form-datepicker>
            <b-form-invalid-feedback id="dateFeedback">
              <!-- This will only be shown if the preceeding input has an invalid state -->
              Este campo tiene que ser llenado
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            horizontal
            :label-cols="6"
            label="Numero de documento (ticket, factura o credito fiscal) :"
            label-for="document"
          >
            <b-form-input
              size="sm"
              v-model="inventory.documentNumber"
              :state="documentState"
              type="text"
              aria-describedby="documentFeedback"
            ></b-form-input>

            <b-form-invalid-feedback id="documentFeedback">
              <!-- This will only be shown if the preceeding input has an invalid state -->
              Este campo tiene que ser llenado
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group horizontal :label-cols="6" label="Movimiento:" label-for="movement">
            <b-form-select
              v-model="inventory.movement"
              class="mb-3"
              size="sm"
              id="movement"
              name="movement"
              :state="movementState"
              aria-describedby="movementFeedback"
              @change="resetInventoryForm(true, inventory.movement)"
            >
              <option :value="movement" v-for="(movement, bind) in movements" v-bind:key="bind">
                {{ movement == 'IN' ? 'Entrada' : movement == 'OUT' ? 'Salida' : movement }}
              </option>
            </b-form-select>
            <b-form-invalid-feedback id="movementFeedback">
              <!-- This will only be shown if the preceeding input has an invalid state -->
              Este campo tiene que ser llenado
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group horizontal :label-cols="6" label="Detalle: Escoger o entrar el texto" label-for="movement">
            <b-form-input
              size="sm"
              list="movement_detail"
              v-model="inventory.movement_detail"
              :state="movementDetailState"
              aria-describedby="movementDetailFeedback"
            ></b-form-input>

            <datalist id="movement_detail">
              <option v-for="detail in movementDetails">{{ detail }}</option>
            </datalist>

            <b-form-invalid-feedback id="movementDetailFeedback">
              <!-- This will only be shown if the preceeding input has an invalid state -->
              Este campo tiene que ser llenado
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            v-if="inventory.movement == 'IN'"
            horizontal
            :label-cols="6"
            label="Proveedor:"
            label-for="provider"
          >
            <b-form-select v-model="inventory.provider" class="mb-3" size="sm" id="provider" name="provider">
              <option :value="provider" v-for="(provider, bind) in providers" v-bind:key="bind">
                {{ provider.name }}
              </option>
            </b-form-select>
          </b-form-group>

          <b-form-group horizontal :label-cols="6" label="Cantidad:" label-for="quantity_movement">
            <b-form-input
              id="quantity_movement"
              type="number"
              min="1"
              :max="maxQuantity"
              name="quantity_movement"
              v-model="inventory.quantity_movement"
              placeholder="Cantidad"
              size="sm"
              :state="quantityMovementState"
              aria-describedby="quantityFeedback"
              @input="fillInventoryTotals()"
            ></b-form-input>
            <b-form-invalid-feedback id="quantityFeedback">
              <!-- This will only be shown if the preceeding input has an invalid state -->
              La cantidad tiene que ser positiva y el nuevo saldo tambien.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group horizontal :label-cols="6" label="Precio en USD:" label-for="price_movement">
            <b-form-input
              id="price_movement"
              type="number"
              min="0.01"
              step="0.01"
              name="price_movement"
              v-model="inventory.price_unit_movement.amount"
              placeholder="Precio"
              size="sm"
              :state="priceMovementState"
              aria-describedby="priceMovementFeedback"
              @input="fillInventoryTotals()"
            ></b-form-input>
            <b-form-invalid-feedback id="priceMovementFeedback">
              <!-- This will only be shown if the preceeding input has an invalid state -->
              La cantidad tiene que ser positiva y el nuevo saldo tambien.
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </article>
    </div>

    <div>
      <h3>Nuevo movimiento (borrador)</h3>
      <table class="table table-striped" id="content_loop">
        <thead>
          <tr style="text-align:center;">
            <th colspan="7"></th>
            <th colspan="3">
              Movimiento
            </th>
            <th colspan="3">
              Saldo
            </th>
          </tr>
          <tr>
            <th>Fecha del registro</th>
            <th>Documento fecha</th>
            <th>Documento N.</th>
            <th>Producto</th>
            <th>Proveedor</th>
            <th>Movimiento</th>
            <th>Detalle</th>
            <th>Cantidad</th>
            <th>Precio Unitario</th>
            <th>Total</th>
            <th>Cantidad</th>
            <th>Precio Unitario</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="managePermissionGranted"
            v-bind:class="{
              'new-inventory-line-in': inventory.movement == 'IN',
              'new-inventory-line-out': inventory.movement == 'OUT'
            }"
          >
            <td>{{ moment().format("DD/MM/YYYY, h:mm a") }}</td>
            <td>{{ moment(inventory.date).format("DD/MM/YYYY") }}</td>
            <td>{{ inventory.documentNumber }}</td>
            <td>{{ inventory.product.name }}</td>
            <td>{{ inventory.provider && inventory.provider.name ? inventory.provider.name : '' }}</td>
            <td>
              {{ inventory.movement == 'IN' ? 'Entrada' : inventory.movement == 'OUT' ? 'Salida' : inventory.movement }}
            </td>
            <td>{{ inventory.movement_detail }}</td>
            <td>{{ inventory.quantity_movement }}</td>
            <td>${{ inventory.price_unit_movement.amount }}</td>
            <td>${{ inventory.total_movement.amount }}</td>
            <td>{{ inventory.quantity_inventory }}</td>
            <td>${{ inventory.price_unit_inventory.amount }}</td>
            <td>${{ inventory.total_inventory.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="form-group">
      <button
        v-if="managePermissionGranted"
        type="button"
        @click="handleAddInventory()"
        class="au-btn au-btn-icon au-btn--green"
      >
        Agregar nuevo movimiento
      </button>
      &nbsp;
      <button type="button" @click="closeInventoryForm()" class="au-btn au-btn-icon au-btn--cancel">Volver atras</button>
    </div>

    <inventory-history 
      :deleteEnabled="managePermissionGranted" 
      :inventoryMovements="productInventoryMovements"
      @updated="getInventoryForChosenProduct">
    </inventory-history>

    <confirmation-page
      v-if="showSuccessModal"
      title="Movimiento guardado con exito"
      text="Continuar en este mismo producto o volver al inventario general ?"
      okTitle="Volver al inventario general"
      @yes="closeInventoryForm()"
      @no="showSuccessModal = false;getInventoryForChosenProduct();"
      cancelTitle="Continuar aqui"
    />
  </div>
</template>

<script>
import Permission from '@/constants/permissions';
import { mapGetters } from 'vuex';
import ConfirmationPage from '@/components/common/ConfirmationPage.vue';
import InventoryHistory from './InventoryHistory.vue';
import moment from 'moment';

export default {
components: {
    ConfirmationPage,
    InventoryHistory
  }, 
  name: 'InventoryPage',
  props: {
    productObj: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      inventory: {
        date: new Date(),
        documentNumber: '',
        adminEmail: this.adminEmail,
        product: null,
        provider: {
          _id: null,
          name: null
        },
        movement: 'IN',
        movement_detail: '',
        quantity_movement: 0,
        price_unit_movement: {
          amount: 0,
          currency: 'USD'
        },
        total_movement: {
          amount: 0,
          currency: 'USD'
        },
        quantity_inventory: 0,
        price_unit_inventory: {
          amount: 0,
          currency: 'USD'
        },
        total_inventory: {
          amount: 0,
          currency: 'USD'
        }
      },
      showInventoryForm: false,
      productSearch: '',
      movements: ['IN', 'OUT'],
      productInventoryMovements: [],
      showSuccessModal: false,
      maxQuantity: undefined
    };
  },
  async created() {
    this.inventory.product = this.productObj;
    await this.getInventoryForChosenProduct();
    await this.$store.dispatch('providerStore/getProviders');
  },
  computed: {
    movementDetails() {
      if (this.inventory.movement) {
        if (this.inventory.movement == 'IN') {
          return ['Compra', 'Regreso de cliente'];
        } else if (this.inventory.movement == 'OUT') {
          return ['Venta', 'Regreso al proveedor'];
        }
      }
      return ['Entrada: Compra', 'Entrada: Regreso de proveedor', 'Salida: Venta', 'Salida: Regreso de cliente'];
    },
    products() {
      const allProducts = this.$store.getters['adminStore/allProducts'];
      return allProducts.map(product => {
        return {
          value: product,
          text: product.name + ' - ' + product.code
        };
      });
    },
    adminEmail(){
      return this.$store.getters['authStore/getEmail'];
    },
    providers() {
      return this.$store.getters['providerStore/getProviders'];
    },
    dateState() {
      return this.inventory.date != null;
    },
    documentState() {
      return (
        this.inventory.documentNumber &&
        this.inventory.documentNumber != null &&
        this.inventory.documentNumber.length > 0
      );
    },
    movementState() {
      return this.inventory.movement && this.inventory.movement.length > 0;
    },
    movementDetailState() {
      return (
        this.inventory.movement_detail &&
        this.inventory.movement_detail != null &&
        this.inventory.movement_detail.length != '' &&
        this.inventory.movement_detail.length > 0
      );
    },
    quantityMovementState() {
      return (
        this.inventory.quantity_movement != null &&
        this.inventory.quantity_movement > 0 &&
        !(this.inventory.total_inventory.amount < 0 || this.inventory.quantity_inventory < 0)
      );
    },
    priceMovementState() {
      return (
        this.inventory.price_unit_movement.amount != null &&
        this.inventory.price_unit_movement.amount > 0 &&
        !(this.inventory.total_inventory.amount < 0 || this.inventory.quantity_inventory < 0)
      );
    },
    managePermissionGranted() {
      if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
      return this.permissions && this.permissions.indexOf(Permission.INVENTORY_MANAGE) >= 0;
    },
    ...mapGetters({
      permissions: 'authStore/permissions'
    })
  },
  methods: {
    moment,
    closeInventoryForm(){
      this.showSuccessModal = false;
      this.showErrorModal = false;
      this.resetInventoryForm(false);
      this.$emit('close');
    },
    resetInventoryForm(keepProduct, mvt) {
      this.movements = ['IN', 'OUT'];
      let cacheProduct = null;
      if (keepProduct) cacheProduct = this.inventory.product;
      this.inventory = {
        date: new Date(),
        documentNumber: null,
        adminEmail: this.adminEmail,
        product: cacheProduct,
        provider: {
          _id: null,
          name: null
        },
        movement: mvt ? mvt : 'IN',
        movement_detail: null,
        quantity_movement: 0,
        price_unit_movement: {
          amount: 0,
          currency: 'USD'
        },
        total_movement: {
          amount: 0,
          currency: 'USD'
        },
        quantity_inventory: 0,
        price_unit_inventory: {
          amount: 0,
          currency: 'USD'
        },
        total_inventory: {
          amount: 0,
          currency: 'USD'
        }
      };

      if (mvt == 'OUT' && this.productInventoryMovements.length > 0) {
        const latestMovement = this.productInventoryMovements[0];
        const inputMovements = this.productInventoryMovements.filter(movement => movement.movement == 'IN');
        const nextMovement = this.calculateNextOutput(latestMovement?.quantity_inventory, inputMovements)
        this.maxQuantity = nextMovement.quantity;
        this.inventory.quantity_movement = 1;
        this.inventory.price_unit_movement.amount = nextMovement.price;
      }else{
        this.maxQuantity = undefined;
        this.inventory.quantity_movement = 0;
        this.inventory.price_unit_movement.amount = 0;
      }
    },
    async getInventoryForChosenProduct() {
      this.resetInventoryForm(true);
      this.productInventoryMovements = await this.$store.dispatch(
        'inventoryStore/getInventoryById',
        this.inventory.product._id
      );

      if (this.productInventoryMovements.length == 0) this.movements = ['IN'];
    },
    calculateNextOutput(qtyResidue, inputMovements) {
      const lastInputMovement = inputMovements.shift();
      const qtyInput = lastInputMovement.quantity_movement;
      const qtyDifference = qtyResidue - qtyInput;

      if (qtyDifference <= 0 || inputMovements.length == 0) {
        return {
          price : lastInputMovement.price_unit_movement.amount,
          quantity : lastInputMovement.quantity_movement + qtyDifference
        }
      } else {
        return this.calculateNextOutput(qtyDifference, inputMovements);
      }
    },
    validateForm() {
      return (
        this.dateState &&
        this.movementState &&
        this.movementDetailState &&
        this.quantityMovementState &&
        this.priceMovementState &&
        this.inventory.total_inventory.amount >= 0 &&
        this.inventory.quantity_inventory >= 0
      );
    },
    fillInventoryTotals() {
      if (this.productInventoryMovements.length > 0 && this.productInventoryMovements[0].quantity_inventory > 0) {
        const latestMovement = this.productInventoryMovements[0];
        //this.inventory.price_unit_movement.amount = latestMovement.total_inventory.amount / latestMovement.quantity_inventory;
        this.inventory.total_movement.amount =
          this.inventory.quantity_movement * this.inventory.price_unit_movement.amount;
        if (this.inventory.movement == 'IN') {
          this.inventory.quantity_inventory =
            parseInt(latestMovement.quantity_inventory) + parseInt(this.inventory.quantity_movement);
          this.inventory.total_inventory.amount =
            Math.round((latestMovement.total_inventory.amount + this.inventory.total_movement.amount) * 100) / 100;
          this.inventory.price_unit_inventory.amount =
            Math.round((this.inventory.total_inventory.amount / this.inventory.quantity_inventory) * 100) / 100;
        } else if (this.inventory.movement == 'OUT') {
          this.inventory.quantity_inventory =
            parseInt(latestMovement.quantity_inventory) - parseInt(this.inventory.quantity_movement);
          this.inventory.total_inventory.amount =
            Math.round((latestMovement.total_inventory.amount - this.inventory.total_movement.amount) * 100) / 100;
          this.inventory.price_unit_inventory.amount =
            Math.round(
              (this.inventory.quantity_inventory > 0
                ? this.inventory.total_inventory.amount / this.inventory.quantity_inventory
                : 0) * 100
            ) / 100;
          if (this.inventory.total_inventory.amount < 0 || this.inventory.quantity_inventory < 0) {
            this.$notify({
              group: 'all',
              type: 'error',
              text: 'El nuevo saldo es negativo ! Verificar los valores de cantidad y precio.'
            });
          }
        }
      } else {
        this.inventory.total_movement.amount =
          parseInt(this.inventory.quantity_movement) * this.inventory.price_unit_movement.amount;

        if (this.inventory.movement == 'IN') {
          this.inventory.quantity_inventory = parseInt(this.inventory.quantity_movement);
          this.inventory.total_inventory.amount = this.inventory.total_movement.amount;
          this.inventory.price_unit_inventory.amount = this.inventory.price_unit_movement.amount;
        } else if (this.inventory.movement == 'OUT') {
          this.$notify({
            group: 'all',
            type: 'error',
            text: 'Imposible de hacer salida con inventario inexistente ! '
          });
        }
      }
    },
    async handleAddInventory() {
      if (!this.validateForm()) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Error en el formulario. Verificar los valores ! '
        });
        return;
      }

      try {
        const inventoryToSave = {... this.inventory};
        inventoryToSave.product = {_id: this.inventory.product._id};
        inventoryToSave.provider = {_id: this.inventory.provider._id};
        if (this.inventory.movement == 'OUT') delete inventoryToSave.provider;
        await this.$store.dispatch('inventoryStore/addInventory', inventoryToSave);
        this.showSuccessModal = true;
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo'
        });
      }
    }
  }
};
</script>

<style scoped>
.table th,
.table td {
  padding: 0.3rem;
}
th,
td {
  border: 1px solid gray;
  font-size: 0.9rem;
}
.new-inventory-line > td {
  background-color: rgb(206 152 31 / 74%);
}

.new-inventory-line-in > td {
  background-color: rgb(80 199 65 / 74%);
  border-bottom: solid;
  font-weight: bold;
}

.new-inventory-line-out > td {
  background-color: rgb(228 40 40 / 74%);
  border-bottom: solid;
  font-weight: bold;
}

</style>
