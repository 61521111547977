<template>
  <div>
      <h3>Historial de movimientos</h3>
      <table class="table table-striped" id="content_loop">
        <thead>
          <tr style="text-align:center;">
            <th colspan="7"></th>
            <th colspan="3">
              Movimiento
            </th>
            <th colspan="3">
              Saldo
            </th>
            <th colspan="1" v-if="deleteEnabled"></th>
          </tr>
          <tr>
            <th>Fecha del registro</th>
            <th>Documento fecha</th>
            <th>Documento N.</th>
            <th>Producto</th>
            <th>Proveedor</th>
            <th>Movimiento</th>
            <th>Detalle</th>
            <th>Cantidad</th>
            <th>Precio Unitario</th>
            <th>Total</th>
            <th>Cantidad</th>
            <th>Precio Unitario</th>
            <th>Total</th>
            <th v-if="deleteEnabled">Editar</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-bind:class="{
              'old-inventory-line-in': inventoryLine.movement == 'IN',
              'old-inventory-line-out': inventoryLine.movement == 'OUT'
            }"
            v-for="(inventoryLine, index) in inventoryMovements"
            v-bind:key="index"
          >
            <td>{{ moment(inventoryLine.createdOn).format("DD/MM/YYYY, h:mm a") }}</td>
            <td>{{ moment(inventoryLine.date).format("DD/MM/YYYY") }}</td>
            <td>{{ inventoryLine.documentNumber }}</td>
            <td>{{ inventoryLine.product.name }}</td>
            <td>{{ inventoryLine.provider && inventoryLine.provider.name ? inventoryLine.provider.name : '' }}</td>
            <td>
              {{
                inventoryLine.movement == 'IN'
                  ? 'Entrada'
                  : inventoryLine.movement == 'OUT'
                  ? 'Salida'
                  : inventoryLine.movement
              }}
            </td>
            <td>{{ inventoryLine.movement_detail }}</td>
            <td>{{ inventoryLine.quantity_movement }}</td>
            <td>${{ inventoryLine.price_unit_movement.amount }}</td>
            <td>${{ inventoryLine.total_movement.amount }}</td>
            <td>{{ inventoryLine.quantity_inventory }}</td>
            <td>${{ inventoryLine.price_unit_inventory.amount }}</td>
            <td>${{ inventoryLine.total_inventory.amount }}</td>
            <td class="text-center" v-if="deleteEnabled">
              <a v-if="index == 0"
                @click="deleteInventoryLine(index)">
                <font-awesome-icon icon="trash-alt"></font-awesome-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    inventoryMovements: {
      type: Array,
      required: true
    },
    deleteEnabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    moment,
    async deleteInventoryLine(index) {
      try {
        await this.$store.dispatch('inventoryStore/deleteInventory', this.inventoryMovements[index]._id);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'El registro se eliminó correctamente'
        });
        this.$emit('updated')
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo'
        });
      }
    },
  }
}
</script>

<style scoped>
.table th,
.table td {
  padding: 0.3rem;
}
th,
td {
  border: 1px solid gray;
  font-size: 0.9rem;
}

.old-inventory-line-in > td {
  background-color: rgb(122 210 111 / 20%);
}

.old-inventory-line-out > td {
  background-color: rgb(231 91 91 / 20%);
}
</style>