<template>
  <b-row>
    <b-col :cols="(enrichedEditing && plainTextEditorEnabled) ? 5 : 10" v-if="enrichedEditing" class="editor" >
      <editor-menu-bar :editor="editorInstance" v-slot="{ commands, isActive }">
        <div class="menubar" >
          <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
            <font-awesome-icon icon="bold" />
          </button>

          <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
            <font-awesome-icon icon="italic" />
          </button>

          <button class="menubar__button" :class="{ 'is-active': isActive.strike() }" @click="commands.strike">
            <font-awesome-icon icon="strikethrough" />
          </button>

          <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
            <font-awesome-icon icon="underline" />
          </button>

          <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
            <font-awesome-icon icon="paragraph" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 2 }) }"
            @click="commands.heading({ level: 2 })"
          >
            H2
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.heading({ level: 3 }) }"
            @click="commands.heading({ level: 3 })"
          >
            H3
          </button>

          <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
            <font-awesome-icon icon="list-ul" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.ordered_list() }"
            @click="commands.ordered_list"
          >
            <font-awesome-icon icon="list-ol" />
          </button>
        
          <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
            <font-awesome-icon icon="quote-left" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.horizontal_rule() }"
            @click="commands.horizontal_rule"
            v-if="enrichedEditing"
          >
            <font-awesome-icon icon="ruler-horizontal" />
          </button>

          <button
            class="menubar__button"
            :class="{ 'is-active': isActive.link() }"
            @click="addLinkToEditor"
            v-if="enrichedEditing"
          >
            <font-awesome-icon icon="link" />
          </button>

          
          <button class="menubar__button" @click="commands.undo">
            <font-awesome-icon icon="undo" />
          </button>

          <button class="menubar__button" @click="commands.redo">
            <font-awesome-icon icon="redo" />
          </button>
        </div>
      </editor-menu-bar>
      <editor-content class="editor__content" :editor="editorInstance" />   
    </b-col>
    
    <b-col :cols="(enrichedEditing && plainTextEditorEnabled) ? 5 : 10" v-if="plainTextEditorEnabled">
      <textarea 
        v-model="value" 
        style="border: 1px solid #ccc; padding: 10px; width: 100%; height: 400px;"
        @input="$emit('input', $event.target.value)" />
    </b-col>


    <b-col cols="2" v-if="enrichedEditing">
      <b-btn size="sm" @click="editPlainText()">Editar HTML</b-btn>
      <b-btn v-if="actionTitle" size="sm" @click="triggerAction()">{{actionTitle}}</b-btn>
    </b-col>
  </b-row>
</template>

<script>
import {
  Blockquote,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Image,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  HorizontalRule,
} from 'tiptap-extensions';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';

export default {
  name: 'TiptapEditor',
  props: {
    enrichedEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    actionTitle: {
      type: String,
      required: false,
    },
    actionEvent: {
      type: String,
      required: false,
      default: 'action',
    },
  },
  watch: {
    value(newValue) {
      const html = this.editorInstance.getHTML();
      if (newValue !== html) {
        this.editorInstance.setContent(newValue);
      }
    },
  },
  components: {
    EditorContent,
    EditorMenuBar,
  },
  data() {
    return {
      plainTextEditorEnabled: false,
      editorInstance: null,
    };
  },
  mounted() {
    this.initializeEditor();
    if (!this.enrichedEditing) {
      this.editPlainText();
    }
  },
  beforeDestroy() {
    this.editorInstance?.destroy();
  },
  methods: {
    triggerAction() {
      this.$emit(`${this.actionEvent}`);
    },
    initializeEditor(){
      this.editorInstance = new Editor({
        extensions: [
          new Blockquote(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new OrderedList(),
          new BulletList(),
          new ListItem(),
          new TodoItem(),
          new TodoList(),
          new Bold(),
          new Italic(),
          new Link(),
          new Strike(),
          new Underline(),
          new History(),
          new HorizontalRule(),
          ...(this.enrichedEditing
            ? [new Image(), new Table(), new TableCell(), new TableHeader(), new TableRow()]
            : []),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          this.$emit('input', getHTML());
        },
      })
    },
    editPlainText() {
      this.plainTextEditorEnabled = !this.plainTextEditorEnabled;
    },
    addLinkToEditor() {
      const url = window.prompt('URL');
      if (url) {
        this.editorInstance.chain().focus().setLink({ href: url }).run();
      }
    },
  },
};
</script>

