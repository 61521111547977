// src/pages/Admin/Products
<template>
	<div class="col-md-12">
		<div v-if="!isAddView">
			<div class="card">
				<header class="card-header">
					<div class="row">
						<div class="col-3">
							<h4 class="card-title mt-2">Catalogo de productos</h4>
						</div>

						<div class="col-9 align-right">
							<button
								type="button"
								class="au-btn au-btn-icon au-btn--green"
								@click="addProductFunc()"
								v-if="permissionGranted"
							>
								+ Crear nuevo producto
							</button>
							<button
								type="button"
								class="au-btn au-btn-icon au-btn--cancel"
								@click="updateCodeInc()"
								v-if="superAdminPermissionGranted && incCodeIsUndefined"
							>
								Actualizar codigos productos
							</button>
              				<button
								type="button"
								class="au-btn au-btn-icon au-btn--cancel"
								@click="updateInventoryLatest()"
								v-if="superAdminPermissionGranted"
							>
								Actualizar inventario
							</button>

						</div>
					</div>
				</header>
				<article class="card-body">
					<b-row>
						<b-col md="6"
							>Lineas por pagina &nbsp;&nbsp;&nbsp;
							<b-form-select
								v-model="pagination.limit"
								:options="perPageOptions"
								style="max-width: 100px"
								size="sm"
								@change="pageLimitChanged"
							/>
						</b-col>
						<b-col md="6">
							<b-pagination
								:total-rows="pagination.total"
								v-model="pagination.page"
								:per-page="pagination.limit"
								@change="pageChanged"
								aria-controls="content_loop"
								align="right"
							/>
						</b-col>
					</b-row>
					<div>
						<div class="row">
							<div class="col-sm">
								<b-form-input
									class="mb-3"
									size="sm"
									v-model="query"
									type="text"
									placeholder="Buscar por nombre"
                  @input="inputQuerySearch = true"
								/>
								<b-form-input
									class="mb-3"
									size="sm"
									v-model="queryCodeInc"
									type="number"
									placeholder="Buscar por codigo correlativo"
                  @input="inputQuerySearch = true"
								/>
							</div>
							<div class="col-sm">
								<b-form-select
									v-model="queryCategory"
									class="mb-3"
									size="sm"
									id="category"
									name="category"
									aria-describedby="categoryFeedback"
								>
									<template #first>
										<b-form-select-option :value="''"
											>--Buscar por categoria--</b-form-select-option
										>
									</template>
									<option
										:value="categoryItem._id"
										v-for="(categoryItem, bind) in categorysList"
										v-bind:key="bind"
									>
										{{ categoryItem.category }} / {{ categoryItem.subcategory }}
									</option>
								</b-form-select>
								<b-form-select
									v-model="queryBrand"
									class="mb-3"
									size="sm"
									id="brand"
									name="brand"
									aria-describedby="categoryFeedback"
								>
									<template #first>
										<b-form-select-option :value="''"
											>--Buscar por marca--</b-form-select-option
										>
									</template>
									<option
										:value="brandItem._id"
										v-for="(brandItem, bind) in brandsList"
										v-bind:key="bind"
									>
										{{ brandItem.name }}
									</option>
								</b-form-select>
							</div>
							<div class="col-sm">
								<b-form-group label="Vendidos en linea"
									class="mb-3"
									size="sm"
									id="active"
									name="activeFilter"
									v-slot="{ ariaDescribedby }">
									<b-form-radio 
										v-model="searchActive" 
										:aria-describedby="ariaDescribedby" 
										name="activeFilter" 
										:value="null">Todos</b-form-radio>
									<b-form-radio 
										v-model="searchActive" 
										:aria-describedby="ariaDescribedby" 
										name="activeFilter" 
										:value="true">Activos</b-form-radio>
									<b-form-radio 
										v-model="searchActive" 
										:aria-describedby="ariaDescribedby" 
										name="activeFilter" 
										:value="false">Inactivos</b-form-radio>
								</b-form-group>
							</div>
							<div class="col-sm align-right">
								<button
									type="button"
									@click="search()"
									class="btn btn-success btn-sm"
								>
									Busqueda avanzada
								</button>
								<br />
								<button
									type="button"
									@click="searchReset()"
									class="btn btn-danger btn-sm"
								>
									Reiniciar busqueda
								</button>
							</div>
						</div>
					</div>

					<table class="table table-striped" id="content_loop" style="margin-top: 10px">
						<thead>
							<tr>
								<th></th>
								<th>Nombre</th>
								<th>Codigo correlativo</th>
								<th>Codigo unico</th>
								<th>Categoria/Subcategoria</th>
								<th>Marca</th>
								<th>Precio contado</th>
								<th>Venta en linea</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody v-if="products.length > 0">
							<tr
								v-for="(product, pid) in products"
								v-bind:key="pid"
								v-if="
									(!inputQuerySearch || product.name?.toUpperCase().includes(query.toUpperCase()) ||
                  product.name_invoice?.toUpperCase().includes(query.toUpperCase()) ||
										product.code?.toUpperCase().includes(query.toUpperCase()) ||
                    product.shortDescription?.toUpperCase().includes(query.toUpperCase()) ) &&
										(product.code_inc?.toString().includes(queryCodeInc))
								"
							>
								<td>
									<img
										v-if="
											product.thumbnailUrls &&
												product.thumbnailUrls.length > 0
										"
										:src="product.thumbnailUrls[0]"
										class="productTableImg"
										crossorigin="anonymous"
									/>
								</td>

								<td>{{ product.name }}
                  <small style="color:#7f7f7f"><i>{{ product.name_invoice }}</i></small>            
                </td>
								<td>{{ product.code_inc }}</td>
								<td>{{ product.code }}</td>
								<td>
									{{
										product.category && product.category.subcategory
											? (product.category.name?.es || product.category.category) +
											  ' / ' +
											  (product.category.nameSubcategory?.es || product.category.subcategory)
											: ''
									}}
								</td>
								<td>{{ product.brand.name }}</td>
								<td>
									${{
										product.price && product.price.amount
											? product.price.amount
											: ''
									}}
								</td>
								<td>
									<span v-if="product.active">SI</span>
									<span v-else>NO</span>
								</td>
								<td>
									<a
										@click="editProductFunc(product._id)"
										v-if="permissionGranted"
									>
										<i class="fa fa-edit" style="color: green"></i>
									</a>
								</td>
								<td>
									<a @click="requestProductDelete(product)" v-if="permissionGranted">
										<i class="fa fa-trash" style="color: red"></i>
									</a>
								</td>
							</tr>
						</tbody>
					</table>

					<b-pagination
						:total-rows="pagination.total"
						v-model="pagination.page"
						:per-page="pagination.limit"
						@change="pageChanged"
						aria-controls="content_loop"
						align="right"
					/>
				</article>
				<!-- card-body end .// -->
			</div>
		</div>
		<add-product v-if="isAddView" @cancelTrigger="isAddView = false" :data="editProductData" />

    <b-modal v-if="productToDelete"
      centered
      title="Borrar un producto"
      visible
      hide-footer
      @hide="cancelDelete()"
    >
      <h3 v-if="productToDelete.inventory_latest">El producto tiene movimientos de inventario. Impossible de borrar.</h3>
      <h3 v-else>Estas a punto de borrar el producto {{ productToDelete.name }}. Estas seguro ? </h3> 

      <button 
        v-if="permissionGranted && !productToDelete.inventory_latest"
        type="button"
        class="au-btn au-btn-icon btn-danger"
        @click="deleteProduct(productToDelete._id)"
      >Borrar
      </button>
      <button
        type="button"
        class="au-btn au-btn-icon au-btn--cancel"
        @click="cancelDelete()"
      >Cerrar
      </button>
    </b-modal>
	</div>
</template>

<script>
import AddProduct from '@/components/homepage/AddProduct.vue';
import Permission from '@/constants/permissions';
import { mapGetters } from 'vuex';

export default {
	components: {
		AddProduct,
	},
	data() {
		return {
			isAddView: false,
			editProductData: null,
			query: '',
			queryCategory: '',
			queryBrand: '',
			queryCodeInc: '',
			perPageOptions: [10, 20, 30, 50, 100],
      		productToDelete: null,
			searchActive: null,
			filter: {},
      inputQuerySearch: false,
		};
	},
	async created() {
		try {
			await this.$store.dispatch('adminStore/getAllProducts');
			await this.$store.dispatch('adminStore/getReferenceData');
			await this.$store.dispatch('categoryStore/getCategorys');
		} catch (error) {
			this.$router.push('/login');
		}
	},
	computed: {
		products() {
			return this.$store.getters['adminStore/allProducts'];
		},

		permissionGranted() {
			if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
			return this.permissions && this.permissions.indexOf(Permission.CATALOG_MANAGE) >= 0;
		},
		superAdminPermissionGranted() {
			if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
		},
		incCodeIsUndefined() {
			return (
				this.products.findIndex(
					product => product.code_inc == undefined || product.code_inc == null
				) >= 0
			);
		},
		...mapGetters({
			permissions: 'authStore/permissions',
			pagination: 'adminStore/pagination',
		}),
		categorysList() {
			return this.$store.getters['categoryStore/getcategorys'];
		},
		refdata() {
			return this.$store.getters['adminStore/allStateData'];
		},
		brandsList() {
			return this.refdata.brands;
		},
	},
	methods: {
    requestProductDelete(product){
      this.productToDelete = product;
    },
    cancelDelete(){
      this.productToDelete = null;
    },
		async searchReset() {
			this.query = '';
			this.queryCategory = '';
			this.queryBrand = '';
			this.queryCodeInc = '';
			await this.search();
		},
		async search() {
      this.inputQuerySearch = false;
			const filter = {};
			if (this.query && this.query != '') {
				filter.searchTerm = this.query;
			}
			if (this.queryCategory && this.queryCategory != '') {
				filter.searchCategory = this.queryCategory;
			}
			if (this.queryBrand && this.queryBrand != '') {
				filter.searchBrand = this.queryBrand;
			}
			if (this.queryCodeInc && this.queryCodeInc != '') {
				filter.searchCodeInc = this.queryCodeInc;
			}
			if (this.searchActive != null) {
				filter.searchActive = this.searchActive;
			}

			try {
				this.pagination.page = 1;
				this.filter = filter;
				const data = await this.$store.dispatch('adminStore/getAllProducts', this.filter);
				this.products.splice(0, this.products.length);
				this.products.push(...data.docs);
				this.pagination.total = data.total;
				this.pagination.limit = data.limit;
				this.pagination.page = data.page;
			} catch (err) {
				console.log('Error occured', err);
			}
		},
		async pageChanged(pageNum) {
			this.pagination.page = pageNum;
			await this.$store.dispatch('adminStore/getAllProducts', this.filter);
		},
		async pageLimitChanged(limit) {
			this.pagination.limit = limit;
			this.pageChanged(1);
			// await this.$store.dispatch("adminStore/getAllProducts");
		},
		async deleteProduct(id) {
			await this.$store.dispatch('adminStore/deleteProduct', id);
      		this.productToDelete = null;
			return searchReset();
		},
		addProductFunc() {
			this.isAddView = true;
			this.editProductData = null;
		},
		async editProductFunc(id) {
			const editProductDetails = await this.$store.dispatch('adminStore/getProduct', id);
			this.editProductData = editProductDetails;
			this.isAddView = true;
		},
		async updateCodeInc() {
			try {
				const data = await this.$store.dispatch('adminStore/updateProductData',{productIncCode:true});
				if (data && data.productIncCode && data.productIncCode.responseData)
					this.$notify({
						group: 'all',
						type: 'success',
						text: data.productIncCode.responseData.length + ' productos actualizados',
					});
			} catch (err) {
				this.$notify({
					group: 'all',
					type: 'error',
					text: 'Un error se produjo',
				});
			}
		},
    async updateInventoryLatest() {
			try {
				const data = await this.$store.dispatch('adminStore/updateProductData',{productInventoryLatest:true});
				if (data && data.productInventoryLatest && data.productInventoryLatest.responseData)
					this.$notify({
						group: 'all',
						type: 'success',
						text: data.productInventoryLatest.responseData.length + ' productos actualizados',
					});
			} catch (err) {
				this.$notify({
					group: 'all',
					type: 'error',
					text: 'Un error se produjo',
				});
			}
		},
	},
};
</script>
<style>
.productTableImg {
	height: 75px;
	widows: 75px;
	border: 1px;
	border-color: cadetblue;
}
</style>

<style lang="scss" scoped>
.home {
	height: 100%;
}
</style>
