<template>
  <div class="col-md-12">
    <div class="row justify-content-left">
      <div v-if="!this.viewOperation" class="col-md-12">
        <div class="card">
          <header class="card-header">
            <div class="row">
              <div class="col-3">
                <h4 class="card-title mt-2">Marcas</h4>
              </div>

              <div class="col-9 align-right">
                <button
                  v-if="managePermissionGranted"
                  type="button"
                  class="au-btn au-btn-icon au-btn--green"
                  @click="handleAddButtonClick()"
                >+ Agregar Marca</button>
              </div>
            </div>
          </header>
          <article class="card-body">
            <input class="form-control" v-model="query" type="text" placeholder="Buscar Marcas">
            <table class="table table-striped" id="content_loop">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Imagen</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(brand, index) in brands"
                  v-if="brands.length > 0 && (brand.name.toUpperCase().includes(query.toUpperCase()))"
                  v-bind:key="index"
                >
                  <td>
                    {{brand.name}}
                  </td>
                  <td >
                    <img v-if="brand.imageUrl" :src="brand.imageUrl" style="width: 100px; height: 100px;">
                  </td>
                  <td>
                    &nbsp;
                    <a v-if="managePermissionGranted" @click="editBrand(brand)">
                      <i class="fa fa-edit" style="color:green"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <!-- card-body end .// -->
        </div>
        <!-- card.// -->
      </div>
      <BrandForm
        v-if="this.viewOperation"
        :open="this.viewOperation" 
        :isNew="this.isAddView" 
        :model="brand" 
        @cancel="handleCancel"
        @edit="handleEdit"
        @add="handleAddData"
        />

      <!-- col.//-->
    </div>
    <!-- row.//-->
  </div>
</template>

<script>
import Permission from '@/constants/permissions';
import { mapGetters } from 'vuex';
import BrandForm from '@/components/brand/BrandForm.vue';

export default {
  components: { BrandForm },

  data() {
    return {
      brand: {
        name: '',
        imageUrl: '',
      },
      isAddView: true,
      viewOperation: false,
      query: '',
    };
  },
  async created() {
    await this.$store.dispatch('brandStore/getBrands');
  },
  computed: {
    brands() {
      return this.$store.getters['brandStore/getBrands'];
    },
    managePermissionGranted() {
      if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
      return this.permissions && this.permissions.indexOf(Permission.CATEGORIES_MANAGE) >= 0;
    },
    ...mapGetters({
      permissions: 'authStore/permissions',
    }),
  },
  methods: {
    resetData() {
      this.brand = {
        name: '',
        imageUrl: '',
      };
      this.isAddView = true;
      this.viewOperation = false;
    },
    editBrand(data) {
      this.viewOperation = true;
      this.brand = _.cloneDeep({ ...data});
      this.isAddView = false;
    },
    handleAddButtonClick() {
      this.viewOperation = true;
    },
    handleCancel() {
      this.resetData();
    },
    async handleAddData(data) {
      try {
        await this.$store.dispatch('brandStore/addBrand', data);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Exitoso',
        });
        this.resetData();
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo',
        });
      }
    },
    async handleEdit(data) {
      try {
        await this.$store.dispatch('brandStore/editBrand', data);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Edicion con exito',
        });
        this.resetData();
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Edicion ha fallado',
        });
      }
    },
  },
};
</script>

<style>
</style>
