import { render, staticRenderFns } from "./ItemOrderDescription.vue?vue&type=template&id=4d68c384&scoped=true&"
import script from "./ItemOrderDescription.vue?vue&type=script&lang=js&"
export * from "./ItemOrderDescription.vue?vue&type=script&lang=js&"
import style0 from "./ItemOrderDescription.vue?vue&type=style&index=0&id=4d68c384&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d68c384",
  null
  
)

export default component.exports