import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Import modules here.
import CartStore from '@/store/cart';
import AuthStore from '@/store/auth';
import AdminStore from '@/store/admin';
import UserStore from '@/store/users';
import TariffStore from '@/store/tariff';
import CategoryStore from '@/store/category';
import OrderStore from '@/store/orders';
import CheckoutStore from '@/store/checkouts';
import FeaturedStore from '@/store/featured';
import BrandStore from '@/store/brand';
import ProviderStore from '@/store/provider';
import InventoryStore from '@/store/inventory';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    // Only persist the cart store in local storage.
    cartStore: state.cartStore,
  }),
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cartStore: CartStore,
    authStore: AuthStore,
    adminStore: AdminStore,
    userStore: UserStore,
    orderStore: OrderStore,
    checkoutStore: CheckoutStore,
    tariffStore: TariffStore,
    categoryStore: CategoryStore,
    featuredStore: FeaturedStore,
    brandStore: BrandStore,
    providerStore: ProviderStore,
    inventoryStore: InventoryStore
  },

  // This plugin automatically make the data from stores persist by saving it into local storage.
  plugins: [vuexLocal.plugin],
});
