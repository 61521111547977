var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"single-list-item"}},[_c('div',{staticClass:"general-padding"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.data.product.brand.name)+" "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.data.product.name))]),_c('br'),_c('a',{staticStyle:{"color":"blue"},attrs:{"href":"#"}},[_vm._v("Product Url")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"blue"},attrs:{"href":`${_vm.shopProductUrl}${_vm.data.product._id}`,"target":"_blank"}},[_vm._v(" Veniqa Url ")])]),_c('b-col',{attrs:{"md":"3"}},[(_vm.data.order_line_level_processing_details)?_c('span',[_c('span',{staticClass:"item-status"},[_vm._v(_vm._s(_vm.data.order_line_level_processing_details.status))])]):_vm._e()]),_c('b-col',{attrs:{"md":"3"}},[(_vm.data.order_line_level_processing_details)?_c('div',[_c('div',{staticClass:"align-right"},[(_vm.data.order_line_level_processing_details.status == 'PROCESSING'
              && _vm.orderStatus != 'RECEIVED' && _vm.permissionGranted)?_c('b-btn',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.fulfillingModalShow = true}}},[_vm._v("Mark as fulfilling")]):_vm._e(),(_vm.data.order_line_level_processing_details.status == 'FULFILLING'
              && _vm.orderStatus != 'RECEIVED' && _vm.permissionGranted)?_c('b-btn',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.shippingModalShow = true}}},[_vm._v("Mark as shipped")]):_vm._e(),(_vm.data.order_line_level_processing_details.status == 'SHIPPED'
              && _vm.orderStatus != 'RECEIVED' && _vm.permissionGranted)?_c('b-btn',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){_vm.deliveredModalShow = true}}},[_vm._v("Mark as Delivered")]):_vm._e()],1)]):_vm._e()])],1),_c('p',{staticStyle:{"font-size":"1rem"}}),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('strong',[_vm._v("Total Count:")]),_vm._v(" "+_vm._s(_vm.data.counts)+" "),_c('br'),_c('strong',[_vm._v("Aggregated Weight:")]),_vm._v(" "+_vm._s(_vm.data.aggregatedWeight.quantity)+" "+_vm._s(_vm.data.aggregatedWeight.unit)+" "),_c('br'),_c('strong',[_vm._v("Aggregated Price:")]),_vm._v(" $ "+_vm._s(_vm.data.aggregatedPrice.amount)+" "),_c('br')]),(_vm.data.order_line_level_processing_details)?_c('b-col',{attrs:{"sm":"6"}},[_c('strong',[_vm._v("Store:")]),_vm._v(" "+_vm._s(_vm.data.product.store)+" "),_c('br')]):_vm._e()],1)],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header align-center",attrs:{"id":"headingOne"}},[_c('h5',{staticClass:"mb-0"},[_c('button',{staticClass:"btn btn-link",attrs:{"type":"button","data-toggle":"collapse","data-target":'#item'+_vm.dataIndex,"aria-expanded":"true","aria-controls":'item'+_vm.dataIndex}},[_vm._v("View more details")])])]),_c('div',{staticClass:"collapse",attrs:{"id":'item'+_vm.dataIndex,"aria-labelledby":"headingOne"}},[_c('div',{staticClass:"card-body"},[_c('item-order-description',{attrs:{"item":_vm.data,"orderStatus":_vm.orderStatus},on:{"edit":_vm.editButtonClicked}})],1)])]),(_vm.fulfillingModalShow)?_c('fulfilling-modal',{attrs:{"fulfillItem":_vm.data.order_line_level_processing_details ?
    _vm.data.order_line_level_processing_details.fulfillment_order_details : null,"editMode":_vm.editMode},on:{"cancel":function($event){return _vm.closeModal()},"fulfill":_vm.fulfillItemOrder}}):_vm._e(),(_vm.shippingModalShow)?_c('shipping-modal',{attrs:{"shippingDetail":_vm.data.order_line_level_processing_details
    ? _vm.data.order_line_level_processing_details.shipment : null,"editMode":_vm.editMode},on:{"cancel":function($event){return _vm.closeModal()},"ship":_vm.markAsShipped}}):_vm._e(),(_vm.deliveredModalShow)?_c('delivered-modal',{attrs:{"deliveryDetail":_vm.data.order_line_level_processing_details
    ? _vm.data.order_line_level_processing_details.delivery : null,"editMode":_vm.editMode},on:{"cancel":function($event){return _vm.closeModal()},"delivered":_vm.markAsDelivered}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }