<template>
    <div>
        <b-col>
            <b-row align-h="center">

                <div class="picture-box">
                    <div class="camera-stream">
                    <vue-web-cam 
                        ref="webcam"
                        :device-id="deviceId"
                        width="100%"
                        @error="onError"
                        @cameras="onCameras"
                        @camera-change="onCameraChange"
                    />
                    </div>
                    <div class="camera-modal-container">
                        <font-awesome-icon class="camera-icon" icon="camera" @click="onCapture" />

                    </div>
                </div>
            </b-row>
            <b-row align-h="center">
                <div class="btn btn-primary" @click="close()">Cerrar</div>
            </b-row>
        </b-col>
    </div>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
    name: 'take-picture',
    components: {
        'vue-web-cam': WebCam
    },
    data() {
        return {
            mediaStream: null,
            deviceId: null,
            devices: [],
            camera: null,
        };
    },

    mounted () {
        this.onStart();
    },
    computed: {
        device: function() {
            return this.devices.find(n => n.deviceId === this.deviceId);
        }
    },
    watch: {
        camera: function(id) {
            this.deviceId = id;
        },
        devices: function() {
            // Once we have a list select the first one
            const [first, ...tail] = this.devices;
            if (first) {
                this.camera = first.deviceId;
                this.deviceId = first.deviceId;
            }
        }
    },
    methods: {
        close(){
            this.$emit("close");
        },
        onCapture() {
            let img = this.$refs.webcam.capture();
            const blob = this.b64toBlob(img);
            this.$emit('imageCapture', blob);
        },
        onStop() {
            this.$refs.webcam.stop();
        },
        onStart() {
            this.$refs.webcam.start();
        },
        onError(error) {
            console.log('Camera error',error);
        },
        onCameras(cameras) {
            this.devices = cameras;
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId;
            this.camera = deviceId;
        },
        b64toBlob(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ab], { type: 'image/jpeg' });
        }
    },
};
</script>
<style scoped>
    .picture-box{
        text-align: center;
    }
    .camera-modal-container {
        position: absolute;
        bottom: 80px;
        left: 40%;
        width: 20%;
        align-items: center;
    }
    .take-picture-button {
        cursor: pointer;
        display: flex;
    }
    .camera-icon{
        color:red;
        align-items: center;
        text-align: center;
        font-size: 40px;
    }
</style>
