<template>
  <div>
    <b-modal
      id="modal-center"
      no-close-on-backdrop
      no-close-on-esc
      size="md"
      visible
      centered
      :title="title"
      :ok-title="okTitle"
      :cancel-title="cancelTitle"
      @ok="yesTriggered()"
      @hide="noTriggered()"
      @cancel="noTriggered()"
    >
      <p>{{text}}</p>
      <div>
        <b-row  v-for="(value, name) in formModel">
          <b-col sm="6">
            <label :for="name">{{ name }}</label>
          </b-col>
          <b-col sm="6">
            <b-form-input
              :id="name"
              type="text"
              :name="name"
              v-model="formObject[name]"
              size="sm"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row  v-for="(inputObject, name) in formModelWithSpec">
          <b-col sm="6">
            <label :for="name">{{ inputObject.label || name }}</label>
          </b-col>
          <b-col sm="6">
            <b-form-input
              :id="name"
              :type="inputObject.type || 'text'"
              :name="name"
              :value="inputObject.value || ''"
              v-model="formObject[name]"
              size="sm"
            ></b-form-input>
          </b-col>
        </b-row>
      </div>

    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationPage',
  props: {
    formModel: {
      required: false,
      type: Object,
      default() {
        return {}
      },
    },
    formModelWithSpec: {
      required: false,
      type: Object,
      default() {
        return {}
      },
    },
    title: {
      required: false,
      type: String,
      default: 'Estas seguro ?',
    },

    text: {
      required: false,
      type: String,
      default: 'Escoge si o no',
    },

    okTitle: {
      required: false,
      type: String,
      default: 'Si',
    },

    cancelTitle: {
      required: false,
      type: String,
      default: 'No',
    },
  },

  data() {
    return {
      formObject: this.formModel || {},
    };
  },
  created(){
    for (const [key, spec] of Object.entries(this.formModelWithSpec)) {
      this.formObject[key] = spec.value;
    }
  },
  methods: {
    yesTriggered() {
      this.$emit('yes', this.formObject);
    },

    noTriggered() {
      this.$emit('no');
    },
  },
};
</script>

<style>
</style>
