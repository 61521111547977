<template>
  <div id="comments" v-if="openOrder!=null">
    <div class="comments">
      <h5>Comments</h5>
      <hr>

      <b-form-textarea
        id="textarea1"
        v-model="commentText"
        placeholder="Enter Comments"
        :rows="2"
        size="sm"
        :max-rows="6"
        @keyup.enter.native="addComment()"
      ></b-form-textarea>
      <div class="comment-separator"></div>
      <div v-if="openOrder.comments && openOrder.comments?.length >0">
        <div v-for="(comment, cind) in openOrder.comments" v-bind:key="cind" class="each-comment">
          <b-row>
            <b-col md="1">
              <div
                class="person-profile"
                v-b-tooltip.hover
                :title="comment.auditLog.createdBy.name"
              >{{comment.auditLog.createdBy.name.charAt(0)}}</div>
            </b-col>
            <b-col md="10">
              <div class="comment-content">{{comment.content}}</div>
              <span><i>{{ moment(comment.auditLog?.createdOn).format('DD/MM/YYYY, h:mm a') }} - {{ comment.auditLog.createdBy?.name }}</i></span>
            </b-col>
            <b-col md="1">
              <div
                class="align-center comment-actions"
                v-if="order && commentOwned(comment.auditLog.createdBy.email) && !editMode"
              >
                <font-awesome-icon
                  icon="edit"
                  v-b-tooltip.hover
                  title="Edit Comment"
                  @click="editModeOn(comment)"
                />
                <br>
                <font-awesome-icon
                  icon="trash"
                  v-b-tooltip.hover
                  title="Delete Comment"
                  @click="deleteComment(comment)"
                />
              </div>
            </b-col>
          </b-row>
          <hr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'CommentsSection',

  data() {
    return {
      commentText: '',
      editMode: false,
      editComment: null,
    };
  },

  methods: {
    moment,
    editModeOn(comment) {
      if (this.editMode) return;

      this.editComment = _.cloneDeep(comment);
      this.commentText = this.editComment.content;
      this.editMode = true;
    },
    async addComment() {
      if (this.commentText.trim().length <= 0) return;
      try {
        // URL is autogenerated because method + Comment
        const reqData = {
          method: 'post',
          data: {
            orderId: this.openOrder._id,
            comment: this.commentText,
          },
        };

        // Checks if it is edit mode.
        console.log('Edit mode', this.editMode);
        if (this.editMode) {
          reqData.method = 'put';
          reqData.data.commentId = this.editComment._id;
        }

        console.log('Req data', reqData);

        const isSuccess = await this.$store.dispatch(
          'orderStore/commentRequest',
          reqData,
        );
        if (isSuccess) {
          this.commentText = '';
          if (this.editMode) {
            this.editMode = false;
            this.editComment = false;
          }
        } else {
          throw new Error('Success was false.');
        }
      } catch (error) {
        console.log('Error', error);
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Comment was not added at the moment. Please try again later.',
        });
      }
    },

    async deleteComment(comment) {
      try {
        const reqData = {
          method: 'delete',
          data: {
            orderId: this.openOrder._id,
            commentId: comment._id,
          },
        };

        const isSuccess = await this.$store.dispatch(
          'orderStore/commentRequest',
          reqData,
        );
        if (isSuccess) {
          this.$notify({
            group: 'all',
            type: 'success',
            text: 'Comment has been deleted.',
          });
        } else throw new Error('Request unsuccessful');
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Comment could not be deleted. Please try again later.',
        });
      }
    },

    commentOwned(email) {
      return email === this.userEmail;
    },
  },

  computed: {
    ...mapGetters({
      order: 'orderStore/openOrder',
      checkout: 'checkoutStore/getOpenCheckout',
      userEmail: 'authStore/getEmail',
    }),
    openOrder() {
      return this.order || this.checkout;
    }
  },
};
</script>

<style lang="scss">
#comments {
  margin-top: 1rem;

  .person-profile {
    background-color: grey;
    padding: 3px;
    color: white;
    font-size: x-large;
    text-align: center;
  }

  .each-comment {
    margin: 10px 0px;
  }

  .comment-separator {
    margin-top: 1rem;
  }

  .comment-actions:hover {
    cursor: pointer;
  }

  .comment-content {
    padding: 10px;
    margin-right: 10px;

    hr {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
}
</style>
