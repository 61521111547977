<template>
  <div style="width: 100%;">
    <h3>
      <b-btn @click="$router.push('/orders');" :variant="ordersVariant">Completados</b-btn>
       | 
      <b-btn @click="$router.push('/orders/checkouts');" :variant="checkoutsVariant">Carritos pendientes</b-btn>
    </h3>
    
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'OrderView',
  computed:{
    ordersVariant(){
      if (this.$route.name == 'orders') return 'primary'
      return '';
    },
    checkoutsVariant(){
      if (this.$route.name == 'checkouts') return 'primary'
      return '';
    }
  }
};
</script>

<style>

</style>
