<template>
  <div class="general-padding" id="order-view">
    <h4>Pedidos comenzados (en espera de pago)</h4>
    <div class="order-list">
      <order-list :orders="checkouts" :isCheckoutsPage="true"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderList from '@/components/orders/OrderList.vue';

export default {
  name: 'CheckoutsMainPage',
  components: {
    OrderList,
  },
  data() {
    return {};
  },

  async created() {
    await this.$store.dispatch('checkoutStore/getCheckouts');
  },

  methods: {
  },

  computed: {
    ...mapGetters({
      checkouts: 'checkoutStore/getCheckouts',
    }),
  },
};
</script>

<style lang="scss" scoped>
#order-view {
  .order-list {
    padding: 20px 0px;
  }
}
</style>
