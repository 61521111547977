<template>
      <div div v-if="this.open" class="col-md-6">
        <div class="card">
          <header class="card-header">
            <h4 v-if="!this.isNew" class="card-title mt-2">Editar Marca</h4>
            <h4 v-else class="card-title mt-2">Agregar Marca</h4>
          </header>
          <article class="card-body">
            <div>
              <div class="form-row">
                <div class="col form-group">
                  <label>Nombre</label>
                  <input
                    class="form-control"
                    v-model="brand.name"
                    required
                    trim
                  >
                </div>
              </div>
              <div class="form-row">
                <div class="col form-group">
                  <label>Imagen</label>
                  <b-form-file 
                    :multiple="false" 
                    v-model="imageObj" 
                    class="mt-3"
                    @input="uploadImages"
                    @change="uploadImages"
                    accept="image/png, image/jpeg"
                   ></b-form-file>

                  <img
                    :src="this.brand.imageUrl" 
                    alt="image for brand" 
                    width="50%" height="auto" />
                </div>
              </div>

              <!-- form-group end.// -->
              <div class="form-group">
                <button
                  v-if="!this.isNew"
                  type="button"
                  @click="handleEdit()"
                  class="au-btn au-btn-icon au-btn--green"
                >Editar Marca</button>
                <button
                  v-else
                  type="button"
                  @click="handleAdd()"
                  class="au-btn au-btn-icon au-btn--green"
                >Agregar Marca</button>
                &nbsp;
                <button
                  type="button"
                  @click="handleCancel()"
                  class="au-btn au-btn-icon au-btn--cancel"
                >Cancelar</button>
              </div>
            </div>
          </article>
          <!-- card-body end .// -->
        </div>
        <!-- card.// -->
      </div>
</template>

<script>
import ProxyUrls from '@/constants/ProxyUrls';

export default {
  name: 'BrandForm',
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    isNew: {
      type: Boolean,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      brand: {
        name: '',
        imageUrl: '',
      },
      imageObj: null,
    };
  },
  methods: {
    handleEdit() {
      this.$emit('edit', this.brand);
    },
    handleAdd() {
      this.$emit('add', this.brand);
    },
    handleCancel() {
      this.$emit('cancel');
    },
    async uploadImages() {
      if (!this.imageObj) {
        return;
      }
      try {
        const res = await this.$axios({
          method: 'get',
          url: ProxyUrls.predefinedUrls,
          params: {
            numberOfFeaturedImages: 1,
          },
        });

        const data = res.data.responseData;
        if (data.featuredImageUrls.length == 1) {
          const imageUpload = data.featuredImageUrls[0];
          const imageUploadResult = await this.$axios({
            headers: {
              'Content-Type': 'image/png',
            },
            method: 'put',
            url: imageUpload.uploadUrl,
            data: this.imageObj,
            withCredentials: false,
          });

          if (imageUploadResult.status == 200 ) {
            this.brand = { ...this.brand, imageUrl: imageUpload.liveUrl};
          }
        }
      } catch (error) {
        console.error('error in uploadImages', error);
      }
    },
  },
  created() {
    if (this.model && !this.isNew) {
      this.brand = this.model;
    }
  },
};
</script>