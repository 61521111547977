<template>
  <div class="general-padding" id="order-view">
    <h4>Pedidos COMPLETADOS</h4>
    <b-tabs>
      <template slot="tabs">
        <b-nav-item :active="orderStatus == 'RECEIVED'" @click="tabClicked('RECEIVED')">Recibidos</b-nav-item>
        <b-nav-item :active="orderStatus == 'CONFIRMED'" @click="tabClicked('CONFIRMED')">Confirmados</b-nav-item>
        <b-nav-item
          :active="orderStatus == 'IN-PROGRESS'"
          @click="tabClicked('IN-PROGRESS')"
        >En curso</b-nav-item>
        <b-nav-item :active="orderStatus == 'COMPLETED'" @click="tabClicked('COMPLETED')">Completados</b-nav-item>
        <b-nav-item :active="orderStatus == 'CANCELLED'" @click="tabClicked('CANCELLED')">Candelados</b-nav-item>
      </template>
    </b-tabs>

    <div class="order-list">
      <order-list :orders="orders"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderList from '@/components/orders/OrderList.vue';

export default {
  name: 'OrdersMainPage',
  components: {
    OrderList,
  },
  data() {
    return {};
  },

  async created() {
    const status = undefined;
    this.$store.commit('orderStore/setOrderStatus', status);
    try {
      await this.$store.dispatch(
        'orderStore/getOrdersByStatus',
        status,
      );
    } catch (error) {
      this.$notify({
        group: 'all',
        type: 'error',
        text: `Error occured while getting orders with status: ${status}`,
      });
    }
  },

  methods: {
    tabClicked(tab) {
      this.$store.dispatch('orderStore/getOrdersByStatus', tab);
    },
  },

  computed: {
    ...mapGetters({
      orderStatus: 'orderStore/orderStatus',
      orders: 'orderStore/orders',
    }),
  },
};
</script>

<style lang="scss" scoped>
#order-view {
  .order-list {
    padding: 20px 0px;
  }
}
</style>
