import Vue from 'vue';
import ProxyUrl from '@/constants/ProxyUrls';

export default {
  namespaced: true,
  state: {
    brands: [],
  },

  actions: {

    async getBrands({
      commit,
    }) {
      try {
        const res = await Vue.prototype.$axios({
          url: ProxyUrl.allBrands,
          withCredentials: true,
          method: 'get',
          data: {},
        });
        commit('setBrands', res.data.responseData);
      } catch (err) {
        throw new Error(err);
      }
    },
    async addBrand({
      dispatch,
    }, brand) {
      try {
        await Vue.prototype.$axios({
          url: ProxyUrl.addBrand,
          withCredentials: true,
          method: 'post',
          data: brand,
        });
        await dispatch('getBrands');
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },
    async editBrand({
      dispatch,
    }, brand) {
      try {
        await Vue.prototype.$axios({
          url: ProxyUrl.editBrand,
          withCredentials: true,
          method: 'put',
          data: brand,
        });

        dispatch('getBrands');
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },

  },
  mutations: {

    setBrands(state, payload) {
      state.brands = payload;
    },
  },
  getters: {

    getBrands(state) {
      return state.brands;
    },
  },
};
