import Vue from 'vue';
import ProxyUrl from '@/constants/ProxyUrls';
import Pagination from '@/dto/Pagination.json';

export default {
  namespaced: true,
  state: {
    inventorys: [],
    products: [],
    pagination: _.cloneDeep(Pagination),
  },

  actions: {

    async getInventorys({
      commit,
    }) {
      try {
        const res = await Vue.prototype.$axios({
          url: ProxyUrl.allInventorys,
          withCredentials: true,
          method: 'get',
          params: {
            "searchObj": {
            },
            "sortRule": {
                "createdOn": -1
            }
          }
        });
        commit('setInventorys', res.data.responseData);
      } catch (err) {
        throw new Error(err);
      }
    },
    async getInventoryById({
      // eslint-disable-next-line no-unused-vars
      commit,
    }, id) {
      try {
        const res = await Vue.prototype.$axios({
          url: ProxyUrl.allInventorys,
          withCredentials: true,
          method: 'get',
          params: {
            "searchObj": {
              product: { 
                _id: id
              }
            },
            "sortRule": {
                "createdOn": -1
            }
          }
        });
        if (res.data?.responseData?.length > 0) {
          commit('setInventorys', res.data.responseData);
          return res.data.responseData;
        }
        return []
      } catch (err) {
        throw new Error(err);
      }
    },
    async addInventory({
      dispatch,
    }, inventory) {
      try {
        await Vue.prototype.$axios({
          url: ProxyUrl.addInventory,
          withCredentials: true,
          method: 'post',
          data: inventory,
        });
        await dispatch('getInventorys');
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },
    async getAllProductsWithInventory({
      commit,
      state,
    }, filters) {
      try {
        let reqPayload = {};
        if (filters) {
          reqPayload = filters;
          if (!reqPayload.pagingOptions) reqPayload.pagingOptions = state.pagination;
        } else {
          reqPayload = {
            // searchFilters: filters || {
            //   store: '',
            //   category: '',
            // },

            pagingOptions: state.pagination,
          };
        }

        const {
          data,
        } = await Vue.prototype.$axios({
          url: ProxyUrl.allInventoryByCatalog,
          withCredentials: true,
          method: 'post',
          data: reqPayload,
        });
        if (data && data.httpStatus === 200) {
          commit('setProducts', data.responseData.docs);
          commit('setPagination', data.responseData);
        } else throw new Error('No content');

        return data.responseData;
      } catch (err) {
        throw new Error(err);
      }
    },
    async downloadInventory({
      dispatch,
    }, {filter, format}) {
      try {
        let response = undefined;
        if (filter){
          response = await Vue.prototype.$axios({
            url: ProxyUrl.downloadInventory.replace(':fileFormat', format),
            withCredentials: true,
            method: 'get',
            responseType: 'blob',
            params: {
              dateFrom: filter.dateFrom,
              dateTo: filter.dateTo,
              movement: filter.movement
            },
          });
        }else {
          response = await Vue.prototype.$axios({
            url: ProxyUrl.downloadInventory.replace(':fileFormat', format),
            withCredentials: true,
            method: 'get',
            responseType: 'blob'
          });
        }
        return response;
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },
    async deleteInventory({
      dispatch,
    }, id) {
      try {
        await Vue.prototype.$axios({
          url: ProxyUrl.deleteInventory.replace(':id', id),
          withCredentials: true,
          method: 'delete'
        });
        await dispatch('getInventorys');
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    }
  },
  mutations: {

    setInventorys(state, payload) {
      state.inventorys = payload;
    },
    setProductsWithInventory(state, payload) {
      state.products = [];
      state.products.push(...payload);
    },

    setPagination(state, payload) {
      if (payload) {
        state.pagination.page = payload.page;
        state.pagination.total = payload.total;
        state.pagination.limit = payload.limit;
      }
    },
    setProducts(state, payload) {
      state.products = [];
      state.products.push(...payload);
    },
  },
  getters: {
    allProductsWithInventory(state) {
      return state.products;
    },
    pagination(state) {
      return state.pagination;
    },
    getInventorys(state) {
      return state.inventorys;
    },
  },
};
