import Vue from 'vue';
import ProxyUrl from '@/constants/ProxyUrls';

export default {
  namespaced: true,
  state: {
    providers: [],
  },

  actions: {

    async getProviders({
      commit,
    }) {
      try {
        const res = await Vue.prototype.$axios({
          url: ProxyUrl.allProviders,
          withCredentials: true,
          method: 'get',
          data: {},
        });
        commit('setProviders', res.data.responseData);
      } catch (err) {
        throw new Error(err);
      }
    },
    async addProvider({
      dispatch,
    }, provider) {
      try {
        await Vue.prototype.$axios({
          url: ProxyUrl.addProvider,
          withCredentials: true,
          method: 'post',
          data: provider,
        });
        await dispatch('getProviders');
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },
    async editProvider({
      dispatch,
    }, provider) {
      try {
        await Vue.prototype.$axios({
          url: ProxyUrl.editProvider,
          withCredentials: true,
          method: 'put',
          data: provider,
        });

        dispatch('getProviders');
      } catch (err) {
        console.log(err);
        throw new Error(err);
      }
    },

  },
  mutations: {

    setProviders(state, payload) {
      state.providers = payload;
    },
  },
  getters: {

    getProviders(state) {
      return state.providers;
    },
  },
};
