import { render, staticRenderFns } from "./CommentsSection.vue?vue&type=template&id=593f8ab2&"
import script from "./CommentsSection.vue?vue&type=script&lang=js&"
export * from "./CommentsSection.vue?vue&type=script&lang=js&"
import style0 from "./CommentsSection.vue?vue&type=style&index=0&id=593f8ab2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports