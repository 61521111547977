<template>
  <div id="order-list">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>Fecha</th>
          <th>Hecha por</th>
          <th>Numero</th>
          <th>Estado</th>
          <th>Tipo de Pago</th>
          <th>Estado pago</th>
          <th>Precio total</th>
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(order, orderId) in orders" v-bind:key="orderId" class="list-padding">
          <td>{{order.auditLog?.createdOn ? moment(order.auditLog?.createdOn).format('DD-MM-YYYY HH:MM') : '?'}}</td>
          <td>{{order.auditLog?.createdBy?.email ? order.auditLog?.createdBy?.email : '?'}}</td>
          <td>{{order.order_number}}</td>
          <td>{{translationStatus(order.overall_status)}}</td>
          <td>{{order.payment_info[order.payment_info.length - 1]?.source}}</td>
          <td>{{translationPaymentStatus(order.payment_info[order.payment_info.length - 1]?.type)}}</td>
          <td>$ {{paymentInfo(order.payment_info)}}</td>
          <td>
            <b-btn variant="success" size="sm" @click="openOrder(order)">Ver detalle</b-btn>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'OrderList',
  data() {
    return {

    };
  },

  props: ['orders', 'isCheckoutsPage'],
  methods: {
    translationPaymentStatus(status){
      switch (status) {
        case 'PENDING':
          return 'Pendiente'
        case 'SALE':
          return 'Completado'
        case 'FAILED':
          return 'Rechazada'
        default:
          return '?'
      }
    },
    translationStatus(status){
      switch (status) {
        case 'CREATED':
          return 'Creada'
        case 'PENDING-PAYMENT':
          return 'Espera de pago'
        case 'RECEIVED':
          return 'Recibida'
        case 'CONFIRMED':
          return 'En preparación'
        case 'IN-PROGRESS':
          return 'Enviada'
        case 'COMPLETED':
          return 'Completada'
        case 'CANCELLED':
          return 'Cancelada'
        default:
          return '?'
      }
    },
    moment(date){
      return moment(date)
    },
    paymentInfo(array) {
      if (array.length > 0) {
        const obj = array[0];
        return obj.amount_in_usd.amount;
      }

      return '';
    },

    async openOrder(order) {
      try {
        if (this.isCheckoutsPage){
          const isSuccess = await this.$store.dispatch('checkoutStore/getCheckoutById', order);
          if (!isSuccess) {
            throw new Error('Failed to open checkout detail');
          } else {
            this.$router.push('/orders/orderdetail');
          }
        }else{
          const isSuccess = await this.$store.dispatch('orderStore/openOrderDetail', order);
          if (!isSuccess) {
            throw new Error('Failed to open order detail');
          } else {
            this.$router.push('/orders/orderdetail');
          }
        }
        

      } catch (error) {
        console.log('Error occured', error);
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'The Order could not be opened at the moment. Server guys are working on it.',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-padding{
  td{
    padding: 0.3rem;
    padding-top: 10px;
  }
}
</style>
