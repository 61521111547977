<template>
<b-row>
  <b-col cols="3" class="align-center">
    <font-awesome-icon icon="arrow-left" @click="moveImage('right')"/>
  </b-col>
  <b-col cols="6">
    <b-row>
    <b-col cols="3" v-for="(image, key) in images" :key="key"
      class="responsive-image"
      v-show="key < displayLength"
      @click="chooseImage(image, $event)"
      :class="{'featured': image && image.featured == true}"
      >
      <video v-if="image?.largeBlob?.type?.includes('video') || image.displayUrl?.includes('video')"
        draggable="false"
        :src="image ? image.displayUrl: ''"
        crossorigin="anonymous"
        preload="metadata"
        width="100px"
      >
      </video>
      <img v-else
        draggable="false"
        :src="image ? image.displayUrl: ''"
        crossorigin="anonymous"
      >
    </b-col>
  </b-row>
  </b-col>
  <b-col cols="3">
    <font-awesome-icon icon="arrow-right" @click="moveImage('left')"/>
  </b-col>
  <b-col cols="12" class='align-center'>Utiliza las flechas para cambiar el orden de las imagenes. La primera photo sera la principal.</b-col>
</b-row>

</template>

<script>
export default {
  name: 'ImageShowcase',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      displayLength: 4,
    };
  },

  methods: {
    chooseImage(image) {
      this.$emit('imageClick', image);
    },

    moveImage(direction) {
      this.$emit('action', direction);
    },

    doubleClicked(image) {
      image.featured = !image.featured;
    },

  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/global.scss";

.showcase {
  margin: 15px 10px;
}

.responsive-image {
  width: 100px;
  height: auto;
}

.image-config {
  margin: 10px;

  &:hover {
    cursor: pointer;
    border: 1px solid #bdbdbd;
  }
}

.featured{
  border: 2px solid $primary-red;

  &:hover{
    border: 2px solid $primary-red;
  }
}
</style>
