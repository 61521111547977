<template>
  <div class="animsition">
    <div class="page-wrapper">
      <div class="page-header"> 
        <!-- MENU SIDEBAR-->
        <div>
          <div class="logo-header">
            <a href="#">
              <img class="logo-img" src="./../assets/casa-jerusalem-logo.png" alt="Casa Jerusalem" />
            </a>
            <b-button v-b-toggle.sidebar-backdrop><i class="fas fa-bars"></i> Menu</b-button>
          </div>
          
          <b-sidebar id="sidebar-backdrop"
            title="Menu"
            backdrop-variant="dark"
            backdrop
            shadow>
            <div class="px-3 py-2">

              <div class="menu-sidebar2__content js-scrollbar1">
                <div class="account2">
                  <h4 class="name">{{ adminname }}</h4>
                  <a @click="signout()" class="signout">Desconectarse</a>
                </div>
                <nav class="navbar-sidebar2">
                  <ul class="list-unstyled navbar__list">
                    <li class="nav-item" v-if="categoriesViewGranted">
                      <a class="nav-link" @click="navigate('catalog')">
                        <i class="fas fa-list"></i> 
                        Catalogo de productos
                      </a>
                    </li>

                    <li class="nav-item" v-if="categoriesViewGranted">
                      <a class="nav-link" @click="navigate('inventory')">
                        <i class="fas fa-warehouse"></i>
                        Inventario
                      </a>
                    </li>

                    <li class="nav-item" @click="navigate('adminsetting')" v-if="isSuperAdmin">
                      <a class="nav-link">
                        <i class="fas fa-users"></i>
                        Usuarios
                      </a>
                    </li>
                    <!-- <li class="nav-item" @click="navigate('tariffsetting')" v-if="tariffViewGranted">
                      <a class="nav-link">
                        <i class="fa fa-plane"></i>
                        Tarifas
                      </a>
                    </li> -->
                    <li
                      class="nav-item"
                      @click="navigate('categorysetting')"
                      v-if="categoriesViewGranted"
                    >
                      <a class="nav-link">
                        <i class="fa fa-th"></i>
                        Categorias
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      @click="navigate('brandsetting')"
                      v-if="categoriesViewGranted"
                    >
                      <a class="nav-link">
                        <i class="fa fa-th"></i>
                        Marcas
                      </a>
                    </li>
                    <li class="nav-item" v-if="categoriesViewGranted">
                      <a class="nav-link" @click="navigate('provider')">
                        <i class="fas fa-handshake"></i>
                        Proveedores
                      </a>
                    </li>
                    <li class="nav-item" @click="navigate('orders')" v-if="orderPermissionGranted">
                      <a class="nav-link">
                        <i class="fas fa-archive"></i>
                        Ordenes
                      </a>
                    </li>
                    <li class="nav-item" @click="navigate('featured')" v-if="featuredPermissionGranted">
                      <a class="nav-link">
                        <font-awesome-icon icon="star" />Publicaciones destacadas
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </b-sidebar>
        </div>
      </div>

      <!-- <aside class="menu-sidebar2">
        
      </aside> -->
      <!-- END MENU SIDEBAR-->
      <!-- PAGE CONTAINER-->
      <div class="page-container2">
        <!-- STATISTIC-->
        <section class="statistic">
          <div class="section__content section__content--p30">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-12">
                  <div class="statistic__item">
                    <router-view />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- END PAGE CONTAINER-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Permission from '@/constants/permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'sidebar',
  components: {},
  methods: {
    navigate(path) {
      this.$router.push({ path: `/${path}` });
    },

    async signout() {
      try {
        const isSuccess = await this.$store.dispatch('authStore/logout');
        if (isSuccess) {
          this.$notify({
            group: 'all',
            type: 'success',
            text: 'Logout successful'
          });

          this.$router.push('/login');
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Logout was unsuccessful. Please try again later.'
        });
      }
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'authStore/permissions',
      featuredPermissionGranted: 'authStore/featuredPermissionGranted',
      tariffViewGranted: 'authStore/tariffViewGranted',
      categoriesViewGranted: 'authStore/categoriesViewGranted'
    }),

    adminname() {
      return this.$store.getters['authStore/getName'];
    },

    isSuperAdmin() {
      return this.permissions && this.permissions.indexOf(Permission.SUPERADMIN) >= 0;
    },

    orderPermissionGranted() {
      if (this.isSuperAdmin) return true;
      return (
        this.permissions
        && (this.permissions.indexOf(Permission.ORDER_VIEW) >= 0
        || this.permissions.indexOf(Permission.ORDER_MANAGE) >= 0)
      );
    }
  }
};
</script>

<style>
/*
    DEMO STYLE
*/

/* @import './../assets/css/dashboard.css'; */
@import url('https://fonts.googleapis.com/css?family=Cairo');
body {
  font-family: 'Cairo', sans-serif !important;
}

.page-container2{
  padding-left: 0;
}

.logo-header{
  display: flex;
  flex-direction: row;
}
.logo-img{
  max-width: 150px;
}

.name {
  color: white;
}

</style>
