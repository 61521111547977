export default {
  SUPERADMIN: 'SUPERADMIN',
  ORDER_VIEW: 'ORDER_VIEW',
  ORDER_MANAGE: 'ORDER_MANAGE',
  CATALOG_VIEW: 'CATALOG_VIEW',
  CATALOG_MANAGE: 'CATALOG_MANAGE',
  INVENTORY_VIEW: 'INVENTORY_VIEW',
  INVENTORY_MANAGE: 'INVENTORY_MANAGE',
  TARIFF_VIEW: 'TARIFF_VIEW',
  TARIFF_MANAGE: 'TARIFF_MANAGE',
  CATEGORIES_VIEW: 'CATEGORIES_VIEW',
  CATEGORIES_MANAGE: 'CATEGORIES_MANAGE',
  FEATURED_VIEW: 'FEATURED_VIEW',
  FEATURED_MANAGE: 'FEATURED_MANAGE',
  FEATURED_PREVIEW: 'FEATURED_PREVIEW'
};
