<template>
  <div class="home">
    <side-menu/>
  </div>
</template>

<script>

import SideMenu from '@/components/SideBarMenu.vue';

export default {
  name: 'home',
  components: {
    SideMenu,
  },
};
</script>
