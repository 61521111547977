<template>
	<div class="col-md-12">
		<div class="row justify-content-left">
			<div v-if="this.addInventoryOperationProductObj == null" class="col-md-12">
				<div class="card">
					<header class="card-header">
						<div class="row">
							<div class="col-3">
								<h4 class="card-title mt-2">Inventory</h4>
							</div>
						</div>
					</header>
					<article class="card-body">
						<b-row style="margin: 10px">
							<b-col md="6">
								<div>
									<b-button v-b-toggle.collapse-1 variant="primary"
										>Exportar inventario</b-button
									>
									<b-collapse id="collapse-1" class="mt-12">
										<b-card>
											<div style="margin: 10px">
												<b-form-group
													label="Escoger tipo de exporte de inventario"
													v-slot="{ ariaDescribedby }"
												>
													<b-form-radio-group
														id="btn-radios-1"
														v-model="selectedInventoryExport"
														:options="options"
														:aria-describedby="ariaDescribedby"
														name="radios-btn-outline"
														button-variant="outline-primary"
														buttons
													></b-form-radio-group>
												</b-form-group>
												<div v-if="selectedInventoryExport == 'period'">
													<label for="datepicker-dateFrom"
														>Desde la fecha</label
													>
													<b-form-datepicker
														id="datepicker-dateFrom"
														v-model="dateFromFilter"
														locale="es-MX"
													></b-form-datepicker>
													<label for="datepicker-dateTo"
														>Hasta la fecha</label
													>
													<b-form-datepicker
														id="datepicker-dateTo"
														v-model="dateToFilter"
														locale="es-MX"
													></b-form-datepicker>

													<b-form-group
														label="Movimientos"
														v-slot="{ ariaDescribedby }"
													>
														<b-form-radio
															v-model="
																selectedInventoryExportMovement
															"
															:aria-describedby="ariaDescribedby"
															name="some-radios"
															value="ALL"
															>TODO</b-form-radio
														>
														<b-form-radio
															v-model="
																selectedInventoryExportMovement
															"
															:aria-describedby="ariaDescribedby"
															name="some-radios"
															value="IN"
															>ENTRADAS</b-form-radio
														>
														<b-form-radio
															v-model="
																selectedInventoryExportMovement
															"
															:aria-describedby="ariaDescribedby"
															name="some-radios"
															value="OUT"
															>SALIDAS</b-form-radio
														>
													</b-form-group>
												</div>
											</div>
											<b-button size="sm" @click="downloadFile('csv')"
												>Descargar CSV</b-button
											>
                      <b-button size="sm" @click="downloadFile('pdf')"
												>Descargar PDF</b-button
											>
										</b-card>
									</b-collapse>
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col md="6"
								>Lineas por pagina &nbsp;&nbsp;&nbsp;
								<b-form-select
									v-model="pagination.limit"
									:options="perPageOptions"
									style="max-width: 100px"
									size="sm"
									@change="pageLimitChanged"
								/>
							</b-col>
							<b-col md="6">
								<b-pagination
									:total-rows="pagination.total"
									v-model="pagination.page"
									:per-page="pagination.limit"
									@change="pageChanged"
									aria-controls="content_loop"
									align="right"
								/>
							</b-col>
						</b-row>
						<b-row>
							<!-- <input class="form-control" v-model="query" type="text" placeholder="Buscar por producto nombre o codigo" /> -->
							<div>
								<div class="row">
									<div class="col-sm">
										<b-form-input
											class="mb-3"
											size="sm"
											v-model="query"
											type="text"
											placeholder="Buscar por nombre"
                      @input="inputQuerySearch = true"
										/>
										<b-form-input
											class="mb-3"
											size="sm"
											v-model="queryCodeInc"
											type="number"
											placeholder="Buscar por codigo correlativo"
                      @input="inputQuerySearch = true"
										/>
									</div>
									<div class="col-sm">
										<b-form-select
											v-model="queryCategory"
											class="mb-3"
											size="sm"
											id="category"
											name="category"
											aria-describedby="categoryFeedback"
										>
											<template #first>
												<b-form-select-option :value="''"
													>--Buscar por categoria--</b-form-select-option
												>
											</template>
											<option
												:value="categoryItem._id"
												v-for="(categoryItem, bind) in categorysList"
												v-bind:key="bind"
											>
												{{ categoryItem.category }} /
												{{ categoryItem.subcategory }}
											</option>
										</b-form-select>
										<b-form-select
											v-model="queryBrand"
											class="mb-3"
											size="sm"
											id="brand"
											name="brand"
											aria-describedby="categoryFeedback"
										>
											<template #first>
												<b-form-select-option :value="''"
													>--Buscar por marca--</b-form-select-option
												>
											</template>
											<option
												:value="brandItem._id"
												v-for="(brandItem, bind) in brandsList"
												v-bind:key="bind"
											>
												{{ brandItem.name }}
											</option>
										</b-form-select>
									</div>
									<div class="col-sm align-right">
										<button
											type="button"
											@click="search()"
											class="btn btn-success btn-sm"
										>
											Busqueda avanzada
										</button>
										<br />
										<button
											type="button"
											@click="searchReset()"
											class="btn btn-danger btn-sm"
										>
											Reiniciar busqueda
										</button>
										<br />
									</div>
								</div>
							</div>
						</b-row>
						<table class="table table-striped" id="content_loop">
							<thead>
								<tr style="text-align:center;">
									<th colspan="4"></th>
									<th colspan="3" class="border-left border-right">
										Inventario
									</th>
									<th colspan="1"></th>
								</tr>
								<tr>
									<th>Producto Nombre</th>
									<th>Codigo correlativo</th>
									<th>Codigo unico</th>
									<th>Marca</th>
									<th class="border-left">Cantidad</th>
									<th>Precio unitario</th>
									<th class="border-right">Total</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(product, index) in products"
									v-if="
										products.length > 0 &&
											(!inputQuerySearch || product.name?.toUpperCase().includes(query.toUpperCase()) ||
                        product.name_invoice?.toUpperCase().includes(query.toUpperCase()) ||
												product.code?.toUpperCase().includes(query.toUpperCase())) &&
											product.code_inc?.toString().includes(queryCodeInc)
									"
									v-bind:key="index"
								>
                  <td>{{ product.name }}
                    <small style="color:#7f7f7f"><i>{{ product.name_invoice }}</i></small>            
                  </td>
									<td>{{ product.code_inc }}</td>
									<td>{{ product.code }}</td>
									<td>{{ product.brand.name }}</td>
									<td class="border-left">
										{{
											product.inventory_latest
												? product.inventory_latest.quantity_inventory
												: '0'
										}}
									</td>
									<td>
										{{ getAmount(product, { price_unit_inventory: true }) }}
									</td>
									<td class="border-right">
										{{ getAmount(product, { total_inventory: true }) }}
									</td>
									<td>
										&nbsp;
										<a @click="addInventoryForProduct(product)">
											<i class="fa fa-eye" style="color:green"></i>
											Ver detalle
										</a>
									</td>
								</tr>
							</tbody>
						</table>
						<b-pagination
							:total-rows="pagination.total"
							v-model="pagination.page"
							:per-page="pagination.limit"
							@change="pageChanged"
							aria-controls="content_loop"
							align="right"
						/>
					</article>
					<!-- card-body end .// -->
				</div>
				<!-- card.// -->
			</div>
			<div v-if="this.addInventoryOperationProductObj != null" class="col-md-12">
				<inventory-form
					:productObj="addInventoryOperationProductObj"
					@close="handleCloseInventoryForm()"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import InventoryForm from '@/components/inventory/InventoryForm';
import { mapGetters } from 'vuex';

export default {
	components: { InventoryForm },
	name: 'InventoryPage',
	data() {
		return {
			inventoryData: {
				product: {
					name: 'test prodct',
				},
				date: new Date(),
				activity: 'IN',
				qty: 2,
				price: 10,
			},
			addInventoryOperation: false,
			editInventoryOperationObj: undefined,
			addInventoryOperationProductObj: null,
			query: '',
			queryCategory: '',
			queryBrand: '',
			queryCodeInc: '',
			perPageOptions: [10, 20, 30, 50, 100],
			selectedInventoryExport: 'general',
			selectedInventoryExportMovement: 'ALL',
			options: [
				{ text: 'Inventario general', value: 'general' },
				{ text: 'Movimientos por periodo', value: 'period' },
			],
			dateFromFilter: this.getDateString(new Date()),
			dateToFilter: this.getDateString(new Date()),
      inputQuerySearch: false
		};
	},
	async created() {
		await this.$store.dispatch('inventoryStore/getAllProductsWithInventory');
		await this.$store.dispatch('adminStore/getReferenceData');
		await this.$store.dispatch('categoryStore/getCategorys');
	},
	computed: {
		products() {
			return this.$store.getters['inventoryStore/allProductsWithInventory'];
		},
		...mapGetters({
			pagination: 'inventoryStore/pagination',
		}),
		categorysList() {
			return this.$store.getters['categoryStore/getcategorys'];
		},
		refdata() {
			return this.$store.getters['adminStore/allStateData'];
		},
		brandsList() {
			return this.refdata.brands;
		},
	},
	methods: {
		async searchReset() {
			this.query = '';
			this.queryCategory = '';
			this.queryBrand = '';
			this.queryCodeInc = '';
			await this.search();
		},
		async search() {
      this.inputQuerySearch = false;
			const filter = {};
			if (this.query && this.query != '') {
				filter.searchTerm = this.query;
			}
			if (this.queryCategory && this.queryCategory != '') {
				filter.searchCategory = this.queryCategory;
			}
			if (this.queryBrand && this.queryBrand != '') {
				filter.searchBrand = this.queryBrand;
			}
			if (this.queryCodeInc && this.queryCodeInc != '') {
				filter.searchCodeInc = this.queryCodeInc;
			}

			try {
				this.pagination.page = 1;
				const data = await this.$store.dispatch(
					'inventoryStore/getAllProductsWithInventory',
					filter
				);
				this.products.splice(0, this.products.length);
				this.products.push(...data.docs);
				this.pagination.total = data.total;
				this.pagination.limit = data.limit;
				this.pagination.page = data.page;
			} catch (err) {
				console.log('Error occured', err);
			}
		},
		getDateString(date) {
			date = new Date(date);
			return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
		},
		async downloadFile(format) {
			let response = {};
      let defaultFileName = 'inventario';
			if (this.selectedInventoryExport == 'general') {
        defaultFileName += '_general';
				response = await this.$store.dispatch('inventoryStore/downloadInventory', {format});
			} else {
				if (this.selectedInventoryExportMovement === 'ALL') {
          defaultFileName += `_periodo_${this.dateFromFilter}_${this.dateToFilter}`;
					response = await this.$store.dispatch('inventoryStore/downloadInventory', {
            format,
            filter:{
              dateFrom: this.dateFromFilter,
              dateTo: this.dateToFilter,
            }
        });
				} else if (
					this.selectedInventoryExportMovement === 'IN' ||
					this.selectedInventoryExportMovement === 'OUT'
				) {
          defaultFileName += `_periodo_${this.dateFromFilter}_${this.dateToFilter}_${this.selectedInventoryExportMovement}`;
					response = await this.$store.dispatch('inventoryStore/downloadInventory', {
            format,
						filter: {
              dateFrom: this.dateFromFilter,
						  dateTo: this.dateToFilter,
						  movement: this.selectedInventoryExportMovement
            }
					});
				}
			}
			if (response.status === 200) {
        let blob = new Blob([response.data], { type: 'application/'+format });
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = response.headers['x-filename'] || (defaultFileName + format);
				link.click();
			}
		},
		async pageChanged(pageNum) {
			this.pagination.page = pageNum;
			await this.$store.dispatch('inventoryStore/getAllProductsWithInventory');
		},
		async pageLimitChanged(limit) {
			this.pagination.limit = limit;
			this.pageChanged(1);
		},
		getAmount(product, { total_inventory, price_unit_inventory }) {
			if (!product.inventory_latest) return '0 USD';
			if (total_inventory)
				return `${product.inventory_latest.total_inventory.amount} ${product.inventory_latest.total_inventory.currency}`;
			if (price_unit_inventory)
				return `${product.inventory_latest.price_unit_inventory.amount} ${product.inventory_latest.price_unit_inventory.currency}`;
			return '0 USD';
		},
		dateFormat(date) {
			const d = new Date(date);
			return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
		},
		async handleCloseInventoryForm() {
			this.addInventoryOperation = false;
			this.editInventoryOperationObj = null;
			this.addInventoryOperationProductObj = null;
			this.query = '';
			await this.$store.dispatch('inventoryStore/getAllProductsWithInventory');
		},
		addInventoryForProduct(product) {
			this.addInventoryOperationProductObj = product;
		},
	},
};
</script>

<style lang="scss" scoped>
.border-left {
	border-left: 1px solid #dee2e6;
}
.border-right {
	border-right: 1px solid #dee2e6;
}
</style>
