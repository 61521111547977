<template>
  <div class="col-md-12">
    <div class="row justify-content-left">
      <div v-if="!this.viewOperation" class="col-md-12">
        <div class="card">
          <header class="card-header">
            <div class="row">
              <div class="col-3">
                <h4 class="card-title mt-2">Categorias</h4>
              </div>

              <div class="col-9 align-right">
                <button
                  v-if="managePermissionGranted"
                  type="button"
                  class="au-btn au-btn-icon au-btn--green"
                  @click="handleAddButtonClick()"
                >+ Agregar Categoria</button>
              </div>
            </div>
          </header>
          <article class="card-body">
            <input class="form-control" v-model="query" type="text" placeholder="Buscar Categorias">
            <table class="table table-striped" id="content_loop">
              <thead>
                <tr>
                  <th>Categoria</th>
                  <th>Subcategoria</th>
                  <th>Imagen</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(category, index) in categorys"
                  v-if="categorys.length > 0 && (category.category.toUpperCase().includes(query.toUpperCase())
                  || category.subcategory.toUpperCase().includes(query.toUpperCase()))"
                  v-bind:key="index"
                >
                  <td>
                    <b>{{category.name?.es || ''}}</b><br>
                    <i>{{ category.name?.en || '' }}</i><br>
                    {{category.category}}
                  </td>
                  <td>
                    <b>{{category.nameSubcategory?.es || ''}}</b><br>
                    <i>{{category.nameSubcategory?.en || ''}}</i><br>
                    {{category.subcategory}}
                  </td>
                  <td >
                    <img v-if="category.imageUrl" :src="category.imageUrl" style="width: 100px; height: 100px;">
                  </td>
                  <td>
                    &nbsp;
                    <a v-if="managePermissionGranted" @click="editCategory(category)">
                      <i class="fa fa-edit" style="color:green"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </article>
          <!-- card-body end .// -->
        </div>
        <!-- card.// -->
      </div>
      <CategoryForm
        v-if="this.viewOperation"
        :open="this.viewOperation" 
        :isNew="this.isAddView" 
        :categoryModel="category" 
        @cancel="handleCancel"
        @edit="handleEditCategory"
        @add="handleAddCategory"
        />

      <!-- col.//-->
    </div>
    <!-- row.//-->
  </div>
</template>

<script>
import Permission from '@/constants/permissions';
import { mapGetters } from 'vuex';
import CategoryForm from '@/components/categorypage/categoryform.vue';

export default {
  components: { CategoryForm },

  data() {
    return {
      category: {
        category: '',
        subcategory: '',
        name: { es: '', en: ''},
        nameSubcategory: { es: '', en: ''},
        imageUrl: '',
      },
      isAddView: true,
      viewOperation: false,
      query: '',
    };
  },
  async created() {
    await this.$store.dispatch('categoryStore/getCategorys');
  },
  computed: {
    categorys() {
      return this.$store.getters['categoryStore/getcategorys'];
    },
    managePermissionGranted() {
      if (this.permissions.indexOf(Permission.SUPERADMIN) >= 0) return true;
      return this.permissions && this.permissions.indexOf(Permission.CATEGORIES_MANAGE) >= 0;
    },
    ...mapGetters({
      permissions: 'authStore/permissions',
    }),
  },
  methods: {
    resetCategory() {
      this.category = { category: '', subcategory: '', name: { es: '', en: ''}, nameSubcategory: { es: '', en: ''}, imageUrl: ''};
      this.isAddView = true;
      this.viewOperation = false;
    },
    editCategory(categoryData) {
      this.viewOperation = true;
      this.category = _.cloneDeep({ ...categoryData});
      this.category.name = { es: categoryData.name?.es || categoryData.category || '', en: categoryData.name?.en || ''};
      this.category.nameSubcategory = { es: categoryData.nameSubcategory?.es || categoryData.subcategory || '', en: categoryData.nameSubcategory?.en || ''};
      this.category.imageUrl = categoryData.imageUrl || '';
      this.isAddView = false;
    },
    handleAddButtonClick() {
      this.viewOperation = true;
    },
    handleCancel() {
      this.resetCategory();
    },
    async handleAddCategory(newCategory) {
      try {
        await this.$store.dispatch('categoryStore/addCategory', newCategory);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Exitoso',
        });
        this.resetCategory();
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo',
        });
      }
    },
    async handleEditCategory(editCategory) {
      try {
        await this.$store.dispatch('categoryStore/editCategory', editCategory);
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Edicion con exito',
        });
        this.resetCategory();
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Edicion ha fallado',
        });
      }
    },
  },
};
</script>

<style>
</style>
