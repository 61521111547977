
<template>
  <div id="add-product">
    <manage-photo
      v-show="showManagePhoto"
      ref="managephoto"
      :detailedUrls="product.detailedImageUrls?.concat(product.videoUrls)"
      :thumbnailPropUrls="product.thumbnailUrls"
      :productId="product._id"
      @cancel="closePhotoModal()"
    />
    <div v-show="!showManagePhoto">
      <div class="product-head">
        <b-row>
          <b-col>
            <h2>Edicion de productos</h2>
          </b-col>
        </b-row>
      </div>

      <br>

      <div>
        <b-form-group horizontal :label-cols="2" label="Nombre del producto" label-for="productName">
          <b-form-input
            id="productName"
            type="text"
            name="productName"
            :state="productNameState"
            v-model="product.name"
            placeholder="Entrar el nombre o titulo del producto"
            aria-describedby="productNameFeedback"
            size="sm"
            @change="setProductSlug"
          ></b-form-input>
          <b-form-invalid-feedback id="productNameFeedback">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            Este campo tiene que ser llenado
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group horizontal :label-cols="2" label="Nombre administrativo" label-for="productNameInvoice">
          <b-form-input
            id="productNameInvoice"
            type="text"
            name="productNameInvoice"
            v-model="product.name_invoice"
            placeholder="Nombre administrativo o de facturacion"
            size="sm"
          ></b-form-input>
        </b-form-group>

        <b-form-group v-if="product.code" horizontal :label-cols="2" label="Codigo correlativo" label-for="productCodeInc">
          <b-form-input
            id="productCodeInc"
            type="text"
            name="productCodeInc"
            v-model="product.code_inc"
            placeholder="Codigo correlativo"
            size="sm"
            :disabled="true"
          ></b-form-input>
        </b-form-group>

        <b-form-group v-if="product.code" horizontal :label-cols="2" label="Codigo unico" label-for="productCode">
          <b-form-input
            id="productCode"
            type="text"
            name="productCode"
            v-model="product.code"
            placeholder="Product code"
            size="sm"
            :disabled="true"
          ></b-form-input>
        </b-form-group>

        <!-- This is for store -->
        <b-form-group horizontal :label-cols="2" label="Tienda" label-for="store">
          <b-form-select
            v-model="product.store"
            :options="refdata.stores"
            class="mb-3"
            size="sm"
            id="store"
            name="store"
            :state="storeState"
            aria-describedby="storeFeedback"
          />

          <b-form-invalid-feedback id="storeFeedback">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            Este campo tiene que ser llenado
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Brand -->
        <b-form-group horizontal :label-cols="2" label="Marca" label-for="brand">
          <b-row>
            <b-col sm="10">
              <b-form-select
                v-model="product.brand._id"
                class="mb-3"
                size="sm"
                id="brand"
                name="brand"
                :state="brandState"
                aria-describedby="brandFeedback"
              >
                <option :value="brandItem._id" v-for="(brandItem,bind) in brandsList" v-bind:key="bind">
                  {{brandItem.name}}
                  </option>
              </b-form-select>
            </b-col>
            <b-col sm="2">
              <b-btn size="sm" class="mb-3" @click="showBrand = true" sm="3">Agregar nueva marca</b-btn>
            </b-col>
          </b-row>
          <b-modal
            v-model="showBrand"
            centered
            id="modalBrand"
            title="Nueva marca"
            hide-footer
            size="sm"
          >
            <brand-form
              v-if="showBrand"
              @cancel="cancelBrandModal"
              @save="saveBrand"
            />
          </b-modal>
          <b-form-invalid-feedback id="brandFeedback">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            Este campo tiene que ser llenado
          </b-form-invalid-feedback>
        </b-form-group>

        

        <b-form-group horizontal :label-cols="2" label="Informacion adicional" label-for="productName">
          <b-row class="my-1">
            <b-col sm="2">
              <label for="productModel">Modelo: </label>
            </b-col>
            <b-col sm="10">
              <b-form-input
                id="productModel"
                type="text"
                name="productModel"
                v-model="product.model"
                placeholder="Entrar el modelo del producto"
                size="sm"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="2">
              <label for="productStyle">Estilo: </label>
            </b-col>
            <b-col sm="10">
              <b-form-input
                id="productStyle"
                type="text"
                name="productStyle"
                v-model="product.style"
                placeholder="Entrar el estilo del producto"
                size="sm"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="2">
              <label for="productSerial">Numero de serie : </label>
            </b-col>
            <b-col sm="10">
              <b-form-input
                id="productSerial"
                type="text"
                name="productSerial"
                v-model="product.serial"
                placeholder="Entrar el numero de serie del producto"
                size="sm"
              ></b-form-input>
            </b-col>
          </b-row>
        </b-form-group>


        <!-- Category -->
        <b-form-group horizontal :label-cols="2" label="Categoria" label-for="category">
          <b-form-select
            v-model="product.category.category"
            class="mb-3"
            size="sm"
            id="category"
            name="category"
            @input="product.category._id = ''"
            :state="categoryState"
            aria-describedby="categoryFeedback"
          >
            <option :value="cat.category" v-for="(cat,cind) in uniqueCategories" v-bind:key="cind">{{cat.name}}</option>
          </b-form-select>
          <b-form-invalid-feedback id="categoryFeedback">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            Este campo tiene que ser llenado
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Subcategory -->
        <b-form-group horizontal :label-cols="2" label="Sub Categoria" label-for="subcategory">
          <b-form-select
            v-model="product.category._id"
            class="mb-3"
            size="sm"
            id="subcategory"
            name="subcategory"
            :state="subcategoryState"
            aria-describedby="subcategoryFeedback"
          >
            <option
              :value="sub._id"
              v-for="(sub, sid) in filteredSubcategories"
              v-bind:key="sid"
            >{{sub.nameSubcategory?.es || sub.subcategory}}</option>
          </b-form-select>

          <b-form-invalid-feedback id="subcategoryFeedback">Este campo tiene que ser llenado</b-form-invalid-feedback>
        </b-form-group>

        <hr style="border-top: 3px dotted #75c791;">
        <h3 style="text-align: center;"> Venta en linea </h3>

        <b-row class="my-1">
          <b-col sm="2">
            <label for="activeOnline">Activar: </label>
          </b-col>
          <b-col sm="4">
              <toggle-button id="activeOnline" name="activeOnline" v-model="product.active" :labels="{checked: 'Si', unchecked: 'No'}"/>
          </b-col>
          <b-col sm="4">
            <button class="btn btn-primary btn-sm"
              @click="editImages()"
              size="sm" >Gestion de imagenes
            </button>
          </b-col>
        </b-row>

        <b-form-group horizontal :label-cols="2" label="URL pagina producto" label-for="productSlug">
          <b-form-input
            id="productSlug"
            type="text"
            name="productSlug"
            :state="productSlugState"
            v-model="product.slug"
            placeholder="url del producto estilo nombre-unico-del-producto"
            aria-describedby="productSlugFeedback"
            size="sm"
            :disabled="editionMode"
            trim
          ></b-form-input>
          <b-form-invalid-feedback id="productSlugFeedback">
            El url no es valido. Los espacios y caracteres especiales no son permitidos
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Price -->
        <b-form-group horizontal :label-cols="2" label="Precio de venta USD" label-for="price">
          <b-form-input
            id="price"
            type="number"
            step="0.01"
            name="price"
            :state="priceState"
            v-model="product.price.amount"
            placeholder="Entrar el precio de venta final al contado"
            aria-describedby="priceFeedback"
            size="sm"
            @change="setMinimumMarkedPrice"
          ></b-form-input>

          <b-form-invalid-feedback id="priceFeedback">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            Este campo tiene que ser llenado y con valor positivo.
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Marked Price -->
        <b-form-group horizontal :label-cols="2" label="Precio regular antes del descuento" label-for="markedprice">
          <toggle-button 
            id="isDiscounted" 
            name="isDiscounted" 
            v-model="product.isDiscounted" 
            :labels="{checked: 'Descuento aplicado', unchecked: 'Sin descuento'}"
            :width="125"
            color="#CB8589"/>

          <b-form-input
            id="markedprice"
            type="number"
            step="0.01"
            name="markedprice"
            v-model="product.marked_price.amount"
            placeholder="Entrar el precio de venta regular (sin descuento), superior al precio de venta"
            size="sm"
            :state="markedpriceState"
            aria-describedby="markedpriceFeedback"
            :disabled="!product.isDiscounted"
          ></b-form-input>

          <b-form-invalid-feedback id="markedpriceFeedback">
            <!-- This will only be shown if the preceeding input has an invalid state -->
            El valor tiene que ser superior al precio de venta
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- <b-form-group horizontal :label-cols="2" label="Porcentaje de TVA %" label-for="price">
          <b-form-input
            id="tax"
            type="number"
            step="0.01"
            name="tax"
            v-model="product.tax_percentage"
            min="0"
            size="sm"
          ></b-form-input>
        </b-form-group> -->
        <!-- Price -->
        <!-- <b-form-group horizontal :label-cols="2" label="Tariff Category" label-for="tariffcategory">
          <b-form-select
            v-model="product.tariff"
            class="mb-3"
            size="sm"
            id="tariffcategory"
            name="tariffcategory"
            :state="tariffState"
            aria-describedby="tariffFeedback"
          >
            <option
              v-for="(tariff, tid) in tariffCategories"
              v-bind:key="tid"
              :value="tariff._id"
            >{{tariff.name}}</option>
          </b-form-select>

          <b-form-invalid-feedback id="tariffFeedback"> -->
            <!-- This will only be shown if the preceeding input has an invalid state -->
            <!--Este campo tiene que ser llenado
          </b-form-invalid-feedback>
        </b-form-group> -->

        <b-form-group horizontal :label-cols="2" label="Logistica" label-for="onlineShop">
          <b-row class="my-1">
            <b-col sm="12">
              <b-row class="my-1">
                <b-col sm="3">
                  <label for="shippingRatio">Dimensiones del producto en CM : </label>
                </b-col>
                <b-col sm="2">
                  <b-form-input
                    id="dimensionHeigth"
                    type="number" min="0" max="200" step="1"
                    name="dimensionHeigth"
                    v-model="product.dimension.height"
                    size="sm"
                    :state="dimensionState"
                    aria-describedby="dimensionFeedback"
                  ></b-form-input>
                  <label for="dimensionHeigth">Altura en cm</label>
                </b-col>
                <b-col sm="2">
                  <b-form-input
                    id="dimensionWidth"
                    type="number" min="0" max="200" step="1"
                    name="dimensionWidth"
                    v-model="product.dimension.width"
                    size="sm"
                    :state="dimensionState"
                    aria-describedby="dimensionFeedback"
                  ></b-form-input>
                  <label for="dimensionWidth">Ancho en cm</label>
                </b-col>
                <b-col sm="2">
                  <b-form-input
                    id="dimensionLength"
                    type="number" min="0" max="200" step="1"
                    name="dimensionLength"
                    v-model="product.dimension.length"
                    size="sm"
                    :state="dimensionState"
                    aria-describedby="dimensionFeedback"
                  ></b-form-input>
                  <label for="dimensionLength">Largo en cm</label>
                </b-col>
                <b-col sm="3">
                  <span v-if="productVolumeInC807X">= {{productVolumeInM3*100}}% capacidad en un paquete C807X de 1m3.</span>
                  <span v-else>Entregas gestionadas por Casa Jerusalem</span>
                </b-col>
                <b-form-invalid-feedback id="dimensionFeedback">
                    La capacidad tiene que ser superior a 0 cuando esta activo en linea
                  </b-form-invalid-feedback>
              </b-row>

              <b-row class="my-1">
                <b-col sm="3">
                  <label for="in_stock">Inventario de venta : </label>
                </b-col>
                <b-col sm="3">
                    <toggle-button 
                      id="in_stock" 
                      name="in_stock" 
                      v-model="product.in_stock" 
                      :value="product.in_stock" 
                      :labels="{checked: 'Si', unchecked: 'No'}" :sync="true"/><br>
                      <label for="in_stock">Disponible</label>
                </b-col>
                <b-col sm="3">
                  <b-form-input
                    id="in_stock_quantity"
                    type="number" min="0" step="1"
                    name="in_stock_quantity"
                    v-model="product.in_stock_quantity"
                    size="sm"
                    @change="updateInStock"
                  ></b-form-input>
                  <label for="in_stock">Cantidad</label>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group horizontal :label-cols="2" label="Atributos">
          <b-btn size="sm" @click="showAttributes = true">Agregar Atributos</b-btn>

          <table
            class="table table-sm attrib-table"
            v-if="product.customizationOptions.customizations && product.customizationOptions.customizations.length > 0"
          >
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Clave</th>
                <th>Tipo</th>
                <th>Valores</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(attrib, aind) in product.customizationOptions.customizations"
                v-bind:key="aind"
              >
                <td>{{attrib.name}}</td>
                <td>{{attrib.key}}</td>
                <td>{{attrib.type}}</td>
                <td v-if="attrib.type ==='Colors'">{{extractColorValues(attrib)}}</td>
                <td v-else>{{attrib.values ? attrib.values.join(" , ") : ""}}</td>
              </tr>
            </tbody>
          </table>

          <b-modal
            v-model="showAttributes"
            centered
            id="modal1"
            title="Agregar Atributos"
            hide-footer
            size="sm"
          >
            <custom-attributes
              v-if="showAttributes"
              :propValue="product.customizationOptions.customizations"
              @cancel="cancelAttribModal"
              @save="saveAttributes"
            />
          </b-modal>
        </b-form-group>

        <!-- Weight -->
        <!-- <b-form-group horizontal :label-cols="2" label="Weight" label-for="weight">
          <b-row>
            <b-col>
              <b-form-input
                id="weight"
                type="number"
                step="0.01"
                name="weight"
                :state="weightState"
                v-model="product.weight.quantity"
                placeholder="Enter the weight of the item"
                aria-describedby="weightFeedback"
                size="sm"
              ></b-form-input>

              <b-form-invalid-feedback id="itemurlFeedback"> -->
                <!-- This will only be shown if the preceeding input has an invalid state -->
                <!--Este campo tiene que ser llenado or negative.
              </b-form-invalid-feedback>
            </b-col>
            <b-col>
              <b-form-select
                v-model="product.weight.unit"
                :options="refdata.weight_units"
                class="mb-3"
                size="sm"
                id="unit"
                name="unit"
                :state="unitState"
                aria-describedby="unitFeedback"
              />

              <b-form-invalid-feedback id="unitFeedback"> -->
                <!-- This will only be shown if the preceeding input has an invalid state -->
                <!--Este campo tiene que ser llenado
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-form-group> -->

        <!-- Product link at homestoresv.com/products/:slug -->
        <a v-if="editionMode" :href="`${shopProductUrl}${product.slug}`" target="_blank" class="text-center">Ver pagina del producto : {{ `${shopProductUrl}${product.slug}` }}</a>
        <!-- Details -->
        <b-form-group horizontal :label-cols="2" label="Descripcion corta" label-for="details">
          <TiptapEditor
            v-model="product.shortDescription"
            :enrichedEditing="true"
          />
        </b-form-group>

        <!-- Description -->
        <b-form-group horizontal :label-cols="2" label="Descripcion detallada" label-for="description">
          <TiptapEditor
            v-model="product.description_html"
            :enrichedEditing="true"
            actionTitle="Generar con IA"
            actionEvent="gendescription"
            @gendescription="generateDescription()"
          />

        </b-form-group>
      </div>

      <div class="form-group">
        <div class="col-sm-10 col-sm-offset-2">
          <button
            v-if="this.data != null"
            type="button"
            @click="handleEditProduct()"
            class="btn btn-success btn-sm"
          >Editar Producto</button>
          <button
            v-else
            type="button"
            @click="handleAddProduct()"
            class="btn btn-success btn-sm"
          >Agregar Producto</button>
          &nbsp;
          <button
            type="button"
            class="btn btn-danger btn-sm"
            @click="goBack()"
          >Cancelar</button>
        </div>
      </div>
    </div>
    <inventory-history
      :deleteEnabled="false"
      :inventoryMovements="productInventoryMovements">
    </inventory-history>
  </div>
</template>

<script>
import * as _ from 'lodash';
import ManagePhoto from '@/components/homepage/ManagePhoto.vue';
import CustomAttributes from '@/components/homepage/CustomAttributes.vue';
import BrandForm from '@/components/homepage/BrandForm.vue';
import InventoryHistory from '@/components/inventory/InventoryHistory.vue';
import { mapGetters } from 'vuex';
import TiptapEditor from '../common/TiptapEditor.vue';
import validator from 'validator';
import { convertToSlug } from '../../utils/dataParser';

const shopProductUrl = process.env.VUE_APP_SHOP_PRODUCT_BASE_URL;

export default {
  props: {
    data: { required: false, type: Object, default: null },
  },
  components: {
    ManagePhoto,
    CustomAttributes,
    BrandForm,
    InventoryHistory,
    TiptapEditor
  },
  async created() {
    if (this.data != null) {
      // Assign in assigns the values from data to product. This helps reduce the undefined errors by keeping the
      // defaults of product.
      this.product = _.assignIn(this.product, this.data);
      this.editionMode = true;
      // this.product = _.cloneDeep(this.data);
      // this.product.tariff = this.product.tariff._id;
    }
    await this.$store.dispatch('inventoryStore/getInventoryById', this.product._id);
  },
  data() {
    return {
      shopProductUrl,
      editionMode: false,
      showAttributes: false,
      showBrand: false,
      imagesAreEdited: false,
      // preassignedUrls: null,
      product: {
        store: 'Comercial Casa Jerusalem',
        brand: {
          _id: '',
          name: null
          },
        name: '',
        name_invoice: '',
        code: null,
        model: null,
        style: null,
        serial: null,
        slug: null,
        category: {
          _id: null,
          category: null,
          subcategory: null,
        },
        isDiscounted: false,
        marked_price: {
          amount: 0,
          currency: 'USD',
        },
        active: false,
        shipping_ratio_percentage: 1,
        in_stock: false,
        in_stock_quantity: 0,
        thumbnailUrls: [
           // 'https://rmodev.s3.amazonaws.com/casajerusalem-catalog/aefedf4da7133f2bd80f166c1fd47ca26fb2b5a6/thumbnails/b154405d350ebdbdb7787a4a540bef5a418875b3'
        ],
        featuredImageUrls: [
           // 'https://rmodev.s3.amazonaws.com/casajerusalem-catalog/aefedf4da7133f2bd80f166c1fd47ca26fb2b5a6/thumbnails/b154405d350ebdbdb7787a4a540bef5a418875b3',
        ],
        detailedImageUrls: [
           // 'https://rmodev.s3.amazonaws.com/casajerusalem-catalog/aefedf4da7133f2bd80f166c1fd47ca26fb2b5a6/thumbnails/b154405d350ebdbdb7787a4a540bef5a418875b3',
        ],
        videoUrls: [],
        price: {
          amount: null,
          currency: 'USD',
        },
        tax_percentage: 0,
        // tariff: null,
        // weight: {
        //   quantity: 3.2,
        //   unit: 'LB',
        // },
        dimension: {
          width: 0, 
          height: 0, 
          length: 0,
          unit: 'cm'
        },
        custom_attributes: {},
        customizationOptions: {
          customizations: [],
        },
        shortDescription:
          'Descripcion corta del producto',
        description_html:
          'Descripcion detallada del producto',
        colors: [
          { name: 'Negro', hexValue: '#000000' },
          { name: 'Marron', hexValue: '#435ADF' },
        ],
        sizes: ['XS', 'S', 'M', 'L'],
      },

      showManagePhoto: false,
      images: null
    };
  },
  computed: {
    existingImages() {
      return this.product.detailedImageUrls.length + this.product.featuredImageUrls.length + this.product.videoUrls?.length;
    },
    refdata() {
      return this.$store.getters['adminStore/allStateData'];
    },

    ...mapGetters({
      // tariffCategories: 'adminStore/tariffCategories',
      productInventoryMovements: 'inventoryStore/getInventorys',
    }),

    // tariffState() {
    //   if (this.product.tariff == null) return null;
    //   return this.product.tariff.length > 0;
    // },

    productSlugState() {
      return this.product?.slug?.length > 0 && validator.isSlug(this.product.slug)
    },
    productNameState() {
      return this.product.name.length > 0;
    },

    storeState() {
      return this.product.store.length > 0;
    },

    brandState() {
      return this.product.brand._id.length > 0;
    },

    categoryState() {
      if (
        this.product.category.category === undefined
        || this.product.category.category == null
      ) { return null; }
      return this.product.category.category.length > 0;
    },

    subcategoryState() {
      if (
        this.product.category._id === undefined
        || this.product.category._id == null
      ) { return null; }
      return this.product.category._id.length > 0;
    },

    priceState() {
      return this.product.price.amount > 0 && this.product.price.amount != null;
    },

    markedpriceState() {
      return !this.product.isDiscounted || (this.product.isDiscounted && this.product.marked_price.amount > this.product.price.amount);
    },

    shippingRatioState() {
      return !this.product.active || (this.product.active && this.product.shipping_ratio_percentage > 0);
    },

    dimensionState() {
      const dimension = this.product.dimension.height + this.product.dimension.length + this.product.dimension.width;
      return !this.product.active || (this.product.active && dimension > 0)
    },
    productVolume() {
      return this.product.dimension.height*this.product.dimension.width*this.product.dimension.length;
    },
    productVolumeInM3() {
      return this.productVolume/(100*100*100);
    },
    productVolumeInC807X() {
      return this.product.dimension.height <=100 && this.product.dimension.width <=100 && this.product.dimension.length <=100;
    },

    // weightState() {
    //   return (
    //     this.product.weight.quantity > 0 && this.product.weight.quantity != null
    //   );
    // },

    // unitState() {
    //   return this.product.weight.unit.length > 0;
    // },

    uniqueCategories() {
      const set = [...new Set(this.refdata.categories.map(item => ({category: item.category, name: item.name?.es || item.category})))];
      return set;
    },

    filteredSubcategories() {
      if (this.product.category.category == null) { return _.map(this.refdata.categories, 'subcategory'); }
      const val = this.refdata.categories.filter((item) => {
        if (item.category === this.product.category.category) return true;
        return false;
      });
      return val;
    },
    brandsList: {
      get: function () {
        return this.refdata.brands;
      },
      set: function (newBrandList) {
        this.refdata.brands = newBrandList;
      }
    },
  },
  methods: {
    async generateDescription() {
      if (!this.editionMode) {
          this.$notify({
            group: 'all',
            type: 'warn',
            text: 'Nuevo producto ! Guardalo antes de generar la descripcion',
          });
          return;
      }
      if (this.product.shortDescription.length < 100) {
          this.$notify({
            group: 'all',
            type: 'warn',
            text: 'La descripcion corta tiene que tener entre 50 y 160 caracteres minimo',
          });
          return;
      }
      
      try {
        const updatedProduct = await this.$store.dispatch('adminStore/generateDescription', this.product._id);
        this.product.description_html = updatedProduct.description;
        this.$notify({
          group: 'all',
          type: 'success',
          text: 'Descripcion generada. Por favor revisar y ajustar la presentacion y el texto.',
        });
      }catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo al generar la descripcion',
        });
      }
    },
    setMinimumMarkedPrice() {
      const amount = this.product.price.amount;
      if (this.product.isDiscounted && this.product.marked_price.amount < amount) {
        this.product.marked_price = { amount, currency: 'USD' };
      }
    },
    setProductSlug() {
      if (this.editionMode) return;
      this.product.slug = convertToSlug(this.product.name);
    }, 
    editImages() {
      this.showManagePhoto = true;
      this.imagesAreEdited = true;
    },
    updateInStock() {
      this.product.in_stock = this.product.in_stock_quantity > 0;
      console.log('updateInStock',this.product.in_stock)

    },
    closePhotoModal() {
      this.showManagePhoto = false;
    },
    extractColorValues(attribute) {
      return _.map(attribute.values, 'name').join(' , ');
    },
    validateForm() {
      const totalImagesToSave = this.$refs.managephoto.configureParams().numberOfThumbnailAndDetailedImages;
      const imagesState = !this.product.active || (this.product.active && (totalImagesToSave> 0))
      if (!imagesState) {
        this.$notify({
          group: 'all',
          type: 'warn',
          text: 'Se necesita al menos una imagen principal cuando el producto esta activo en venta en linea',
        });
        return false;
      }

      if (!this.product.tax_percentage || this.product.tax_percentage === '')
        this.product.tax_percentage = 0;
      // if (this.tariffState == null) {
      //   this.product.tariff = '';
      // }
      if (!this.categoryState) {
        this.product.category.category = '';
      }

      if (!this.subcategoryState) this.product.category._id = '';
      return (
        this.productNameState
        && this.storeState
        && this.brandState
        && this.categoryState
        && this.subcategoryState
        && this.priceState
        && this.dimensionState
        && imagesState
        && this.markedpriceState
        // && this.priceCreditState
        // && this.itemurlState
        // && this.weightState
        // && this.unitState
        // && this.tariffState
        //&& this.skuState
      );
    },

    cancelAttribModal() {
      this.showAttributes = false;
    },

    saveAttributes(attribs) {
      this.product.customizationOptions.customizations = [];
      this.product.customizationOptions.customizations.push(...attribs);
      this.showAttributes = false;
    },

    cancelBrandModal() {
      this.showBrand = false;
    },

    async saveBrand() {
      const updatedBrandList = this.$store.getters['brandStore/getBrands'];
      this.brandsList = updatedBrandList;
      this.showBrand = false;
    },

    /**
     * @param {Object} payload
     * {
     *    detailedImageUrls: [list of urls]
     *     featuredImageUrls: [list of urls]
     *     thumbnailUrls: [list of urls]
     * }
     */
    imageUploadComplete(payload) {
      this.showManagePhoto = false;
      _.assign(this.product, payload);
    },
    goBack() {
      this.$emit('cancelTrigger');
    },
    async saveImages(){
      if (!this.imagesAreEdited) return true;
      const totalImagesToSave = this.$refs.managephoto.configureParams()
        .numberOfThumbnailAndDetailedImages;
      try {
        const saveImageRes = await this.$refs.managephoto.saveAll();
        if (saveImageRes) {
          this.imageUploadComplete(saveImageRes);
          return true;
        }
      } catch (err) {
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo al guardar las imagenes',
        });
        return false;
      }
    },
    async handleAddProduct() {
      if (!this.validateForm()) return;
      try {
        if (await this.saveImages()){
          await this.$store.dispatch('adminStore/addProduct', this.product);
          this.$emit('cancelTrigger');
        }
      } catch (err) {
        // if (err) this.preassignedUrls = null;
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo',
        });
      }
    },
    async handleEditProduct() {
      if (!this.validateForm()) return;
      try {
        await this.saveImages();

        await this.$store.dispatch('adminStore/editProduct', this.product);
        this.$store.commit('adminStore/resetProducts');
        this.$emit('cancelTrigger');
      } catch (err) {
        // if (err) this.preassignedUrls = err;
        this.$notify({
          group: 'all',
          type: 'error',
          text: 'Un error se produjo',
        });
      }
    },
    getSubCategory() {
      const refState = this.$store.getters['adminStore/allStateData'];
      const x = _.find(refState.refDataPayload.product_categories, {
        name: this.product.category,
      }).subcategories;
      return x;
    },
  },
};
</script>

<style lang="scss" >
.product-head {
  margin-top: 1em;
}

#add-product {
  .modal-dialog {
    max-width: 80% !important;
  }
}

.attrib-table {
  font-size: 0.875rem;
  margin: 10px 0px;
}

.menubar {
  margin-bottom: 1rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: rgba(0, 0, 0, 0);
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;

  &.is-active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.editor__content {
  padding: 0.5rem 0.5rem;
  border-radius: 0.2rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;

  .ProseMirror:focus,
  .ProseMirror-focused {
    outline: none !important;
  }

  p {
    font-family: unset;
    font-size: 0.875rem;
    font-weight: unset;
    line-height: 1em;
    color: unset;
    margin-bottom: 0px !important;
  }

  li::marker {
    color: #000;
    font-size: 0.875rem;
    content: "•"; /* Add bullet point character */
  }
}
</style>
