import Vue from 'vue';
import ProxyUrl from '@/constants/ProxyUrls';
import PageSetup from '@/dto/PageDTO.json';

export default {
  namespaced: true,
  state: {
    checkouts: [],
    pagination: {},
    checkout: null,
  },

  mutations: {
    setCheckouts(state, payload) {
      if (payload.docs) {
        state.checkouts = [];
        state.checkouts.push(...payload.docs);
      }

      state.pagination = {
        total: payload.total,
        limit: payload.limit,
        page: payload.page,
        pages: payload.pages,
      };
    },
    setCheckoutDetail(state, checkout) {
      state.checkout = checkout;
    }
  },

  actions: {
    async getCheckouts({
      commit,
    }) {
      let reqObj = {
        ...PageSetup,
        sortRule: {
          'auditLog.createdOn': -1
        }
      };

      try {
        const {
          data,
        } = await Vue.prototype.$axios({
          url: ProxyUrl.getCheckouts,
          method: 'post',
          data: reqObj
        });

        commit('setCheckouts', data.responseData);
      } catch (error) {
        throw new Error(error);
      }
    },

    async getCheckoutById({
      commit,dispatch
    }, checkout) {
      if (!checkout || !checkout._id) return false;
      const checkoutId = checkout._id;

      try {
        const {
          data,
        } = await Vue.prototype.$axios({
          url: ProxyUrl.getCheckoutById + checkoutId,
          method: 'get',
        });

        if (data.httpStatus === 200) {
          commit('setCheckoutDetail', data.responseData);
          commit('orderStore/setOpenOrder', null, { root: true });
          return true;
        }

        return false;
      } catch (error) {
        throw new Error(error);
      }
    },

    async sendCommercialEmail({
      commit,
      dispatch
    },checkoutId) {
      try {
        const {
          data,
        } = await Vue.prototype.$axios({
          method: 'post',
          url: ProxyUrl.sendCommercialEmail.replace(':checkoutId', checkoutId),
        });

        if (data.httpStatus === 200) {
          dispatch('getCheckoutById', {_id: checkoutId});
          return true;
        }

        return false;
      } catch (error) {
        throw new Error(error);
      }
    },

    async deleteCheckoutById({
      commit,dispatch
    }, checkoutId) {
      try {
        const url = ProxyUrl.deleteCheckoutById.replace(':checkoutId', checkoutId);
        const {
          data,
        } = await Vue.prototype.$axios({
          url,
          method: 'delete',
        });

        if (data.httpStatus === 200) {
          commit('setCheckoutDetail', null);
          return true;
        }

        return false;
      } catch (error) {
        throw new Error(error);
      }
    },
  },

  getters: {
    getCheckouts(state) {
      return state.checkouts;
    },

    getOpenCheckout(state) {
      return state.checkout;
    },
  },
};
